import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Rating from "@mui/material/Rating";
// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from 'antd';
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton"
import SoftAvatar from "components/SoftAvatar";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";
import profile from "assets/images/profile.avif";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ClearIcon from '@mui/icons-material/Clear';
import { Drawer, Input, Upload, Space, Select, DatePicker } from "antd";
import SoftInput from "components/SoftInput";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

let icono;

const authorsTableData = {
  columns: [
    { name: "ID", align: "center" },
    { name: "Nombre", align: "center" },
    { name: "Activo", align: "left" },
    { name: "Calificacion", align: "center" },
    { name: "Creado", align: "center" },
    { name: "opciones", align: "center" },
  ],
};

function ListaTestimonios() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const [counterDataInfo, setCounterDataInfo] = useState([]);
  const user_id = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState();
  const [dataUser, setDataUser] = useState();
  const navigate = useNavigate();
  const [size, setSize] = useState();
  const [ids, setId] = useState();
  const [drawerVisibleEdit, setDrawerVisibleEdit] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [permiso, setPermiso] = useState();
  const [activeMe, setActiveMe] = useState();
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [nombreUsuario, setnombreUsuario] = useState('');

  const fetchUser = async () => {
    try {
      const response = await fetch(apiUrl + "/api/user/" + user_id + "/?format=json");
      const data = await response.json();
      setnombreUsuario(data[0].user.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  useEffect(() => {
    fetchUser();
  }, []);

  const showModal = (data) => {
      setIsModalOpen(true);
      setmodalData(data);
  };
  const handleOk = () => {
      setIsModalOpen(false);
  };
  const handleCancel = () => {
      setIsModalOpen(false);
  };

  const fetchOperadores = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/group/`);
      const data = await response.json();
      setGrupos(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchTableProfile = async () => {
    try {
      const response = await fetch(apiUrl + "/api/testimonios-list/?format=json");
      //console.log("response", response);
      const data = await response.json();
      console.log('data', data[0]);
      const formattedData = data.map(item => {
        // Obtenemos la fecha en formato YYYY-MM-DD
        console.log("{item.activo}", item.activo);
        const creado = new Date(item.created_at).toISOString().split('T')[0];
        const nombre = <SoftTypography type="primary" style={{ fontSize: '14px' }}>{item.nombre}</SoftTypography>;
        const is_active = <Switch checked={item.activo}/>
        const testimonio = item.testimonio
        const calificacion = <Rating
                                name="hover-feedback"
                                value={item.calificacion}
                                precision={1}
                                disabled="true"
                            />
        const opcionesData = (
          <>
            <IconButton
              onClick={() => handleEdit(item)}
              aria-label="Editars"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        );
        return {
          ID: item.id.toString(),
          Nombre: item.nombre,
          Activo: is_active,
          Creado: creado,
          Calificacion: calificacion,
          opciones: opcionesData,
        };
      });
      setCounterData(formattedData);
      
      setInitialData(formattedData);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchTableProfile();
  }, []);

  const closeEditUser = () => {
    setDrawerVisibleEdit(false);
  };
  const handleEdit = (data) => {
    fetchOperadores();
    setDataUser([data]);
    setId(data.id);
    setSize("large");
    setActiveMe(data.activo)
    setDrawerVisibleEdit(true);
  };

  const handleSave = async () => {
    try {
      console.log("activeMe", activeMe);
      console.log("dataUser.id", ids);
      const csrftoken = getCookie("csrftoken");
      const response = await fetch(apiUrl + `/api/testimonio/update/${ids}/`, {
        method: 'POST',
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          activo: activeMe, // Asumiendo que operador es el ID del UserProfile seleccionado
        })
      });
      fetchTableProfile();
      if (response.ok) {
        console.log('Data updated successfully');
      } else {
        console.error('Failed to update data');
      }
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setDrawerVisibleEdit(false);
    }
  };

  const handleClearConsecutivo = () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };

  const handleConsecutivoChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    const filteredData = initialData.filter(row => {
      const nombre = row.Usuario.props.children.toString().toLowerCase();
      return nombre.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  const handleClearNombre= () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };

  const handleNombreChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    const filteredData = initialData.filter(row => {
      const nombre = row.Nombre.props.children.toString().toLowerCase();
      return nombre.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  const renderFilters = () => (
    <SoftBox>
      <Input
        placeholder="Nombre"
        onChange={(event) => handleNombreChange(event)}
        onClear={handleClearNombre}
        style={{ width: 200, margin: '0 10px' }}
      />
    </SoftBox>
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar username={nombreUsuario} />
        <DashboardTable>
            <SoftBox mb={3}>
              <Card>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SoftTypography variant="h6">
                      Lista de Testimonios
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                  sx={{
                      "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                      },
                  }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Filtros por: &nbsp;&nbsp;{renderFilters()}
                  <Table columns={columns} rows={counterData} />
                  </SoftBox>
              </Card>
            </SoftBox>
        </DashboardTable>
      </DashboardLayout>
      <Drawer
        title="Editar Testimonio"
        anchor="right"
        size={size}
        open={drawerVisibleEdit}
        onClose={closeEditUser}
        footer={
          <Space>
            <SoftButton  onClick={closeEditUser}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSave}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            {dataUser && dataUser.map((data) => (
                  <Card key={data.id}>
                    <SoftBox p={2}>
                      <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                        {data.nombre}
                      </SoftTypography>
                      <SoftBox p={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <SoftBox display="flex" flexDirection="column" height="100%">
                              {/* <Switch checked={data.user.active} onChange={() => setFollowsMe(!followsMe)} /> */}
                              <SoftTypography variant="body2" color="text">
                                Usuario Activo?
                              </SoftTypography>
                              <Switch checked={activeMe} onChange={() => setActiveMe(!activeMe)} />
                              <br></br>
                              <Rating
                                name="hover-feedback"
                                value={data.calificacion}
                                precision={1}
                                disabled="true"
                              />
                              <SoftTypography variant="body2" color="text">
                                {data.testimonio}
                              </SoftTypography>
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                    {dataUser.indexOf(data) < dataUser.length - 1 && (
                      <Divider orientation="vertical" sx={{ mx: 0 }} />
                    )}
                  </Card> 
                  
                ))}
          </form>
      </Drawer>
    </>
  );
}

export default ListaTestimonios;
