/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "App";

import { useParams } from 'react-router-dom';
import SoftAvatar from "components/SoftAvatar";
import Card from "@mui/material/Card";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "layouts/arbol/seguimiento_imagen.css";
import ImageList from "@mui/material/ImageList";
import { Image } from "antd";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import SoftButton from "components/SoftButton";


function InfoSeguimiento(id) {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const { numero } = useParams();
  const [nicknameArbol, setnicknameArbol] = useState(null)

  useEffect(() => {
    const fetchTableSeguimiento = async () => {
      try {
        const response = await fetch(apiUrl + "/api/seguimiento/" + numero + "?format=json");
        const data = await response.json();
        //setnicknameArbol(data[0].arbol.nickname);
        const formattedData = data.map(item => {
          const registrodDate = new Date(item.fecha_registro).toISOString().split('T')[0];
          const paragraphs = item.estado_arbol.split("\n").map((paragraph, index) => {
              // Si la línea actual no está vacía, la envolvemos en una etiqueta <p>
              if (paragraph.trim() !== "") {
                return <p key={index}>{paragraph}</p>;
              } else {
                // Si la línea actual está vacía, comprobamos si la siguiente línea también está vacía
                // Si hay múltiples saltos de línea consecutivos, los fusionamos en un solo párrafo vacío
                let j = index + 1;
                while (j < item.estado_arbol.length && item.estado_arbol[j].trim() === "") {
                  j++;
                }
                if (j > index + 1) {
                  return <p key={index}>&nbsp;</p>;
                } else {
                  // Si solo hay un salto de línea, lo dejamos como está
                  return <br key={index} />;
                }
              }
            });
           return (
            <>
              <SoftBox key={item.id} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "Fecha de Registro" }}
                      count={registrodDate}
                      // percentage={{ color: "success", text: "+55%" }}
                      icon={{ color: "info", component: "public" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "Altura" }}
                      count={item.altura}
                      // percentage={{ color: "success", text: "+3%" }}
                      icon={{ color: "info", component: "public" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "Prioridad" }}
                      count={item.atencion_prioritaria.nombre}
                      // percentage={{ color: "error", text: "-2%" }}
                      icon={{ color: "info", component: "emoji_events" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <MiniStatisticsCard
                      title={{ text: "Condición" }}
                      count={item.condicion.nombre}
                      // percentage={{ color: "success", text: "+5%" }}
                      icon={{
                        color: "info",
                        component: "emoji_events",
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xl={12} lg={7}>
                    <Card>
                      <SoftBox p={2}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <SoftBox display="flex" flexDirection="column" height="100%">
                              <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                                Estado de {item.arbol.nickname}
                              </SoftTypography>
                              <SoftBox mb={6}>
                                <SoftTypography variant="body2" color="text">
                                  {paragraphs}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </Grid>
                          <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
                            <SoftBox
                              height="100%"
                              display="grid"
                              justifyContent="center"
                              alignItems="center"
                              bgColor="info"
                              borderRadius="lg"
                              variant="gradient"
                            >
                              <SoftBox
                                component="img"
                                src={`${apiUrl}${item.arbol.imagen_principal}`}
                                alt="waves"
                                display="block"
                                position="absolute"
                                left={0}
                                width="100%"
                                height="100%"
                              />
                              <SoftBox component="img" src={`${apiUrl}${item.arbol.imagen_principal}`} alt="rocket" width="100%" pt={3} />
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </Card>
                  </Grid>
                  {/* <Grid item xs={12} lg={5}>
                    <WorkWithTheRockets />
                  </Grid> */}
                </Grid>
              </SoftBox>
              <Grid container spacing={3} justifyContent="center">
                <Splide options={{ perPage: 4, autoplay: true, arrows: false }}>
                  {item.imagenes.map((imagen) => (
                    <SplideSlide key={imagen.id}>
                      <Grid>
                        <SoftBox key={imagen.id} mb={{ xs: 1, lg: 0 }}>
                          <ImageList variant="masonry" cols={1} gap={20} className="image-list">
                            <Image.PreviewGroup
                              key={item.id}
                              preview={{
                                onChange: (current, prev) =>
                                  console.log(`current index: ${current}, prev index: ${prev}`),
                              }}
                            >
                              <Image
                                style={{ maxWidth: "100%", height: "auto" }}
                                src={`${apiUrl}${imagen.imagen}`}
                                fallback='ssssss'
                              />
                              <br></br>
                              {/* {imagen.observacion}&nbsp; */}
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button>
                                    <SoftButton size="small" color="primary">
                                      Ver Observaciones
                                    </SoftButton>
                                    </Disclosure.Button>
                                    <Disclosure.Panel>
                                      <SoftBox mb={6}>
                                        <SoftTypography variant="body2" color="text">
                                          {imagen.observacion}
                                        </SoftTypography>
                                      </SoftBox>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </Image.PreviewGroup>
                          </ImageList>
                        </SoftBox>
                      </Grid>
                    </SplideSlide>
                  ))}
                </Splide>
      </Grid>
            </>
          );
        });
        setCounterData(formattedData);
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    };
    fetchTableSeguimiento();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        {counterData}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InfoSeguimiento;
