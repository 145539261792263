/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext} from "react";
import { UserContext } from "App";


// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import apiUrl from "domain";
import apiUrl_home from "home_domain";
import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import SoftButton from "components/SoftButton";
import redirectUrl from "domain_redirect";
import SoftAvatar from "components/SoftAvatar";

function DashboardNavbar({ absolute, light, isMini, username }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const user_id = useContext(UserContext);

  const [nombreUsuario, setnombreUsuario] = useState('');
  const [imagenUsuario, setimagenUsuario] = useState('');

  const fetchUser = async () => {
    try {
      console.log('user_id', user_id);
      const response = await fetch(apiUrl + "/api/user/" + user_id + "/?format=json");
      const data = await response.json();
      setnombreUsuario(data[0].user.username);
      setimagenUsuario(data[0].avatar);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  
  useEffect(() => {
    fetchUser();
  }, []);
  
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  function eliminarCookie(nombre) {
    document.cookie = nombre + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' + redirectUrl;
  }

  const handleLogout = () => {
    // Eliminar el user_id y el token del almacenamiento local
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    eliminarCookie('token');
    setTimeout(() => {
      window.location.href = `${apiUrl_home}`;
    }, 2000);
    setTimeout(() => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.contentWindow.localStorage.removeItem("user_id");
      iframe.contentWindow.localStorage.removeItem("token");
      document.body.removeChild(iframe);
  }, 10000);
  };

  const setCerrarModal = () => {
    setModalOpen(false);
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <SoftBox pr={1}>
              <SoftInput
                placeholder="Buscar..."
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox> */}
            <SoftBox color={light ? "white" : "inherit"}>
              {user_id && (
                <>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    {nombreUsuario}&nbsp;&nbsp;
                  </SoftTypography>
                  <Link onClick={openModal}>
                    <IconButton
                      sx={navbarIconButton}
                      size="small"
                      style={{
                        backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                        borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                        width: "147px", // Ajusta el ancho según tus necesidades
                        height: "40px" // Ajusta la altura según tus necesidades
                      }}
                    >
                      {/* <Icon
                        sx={({ palette: { dark, white } }) => ({
                          color: light ? white.main : dark.main,
                        })}
                      >
                        account_circle
                      </Icon> */}
                      <SoftAvatar
                        src={imagenUsuario ? `${apiUrl}${imagenUsuario}` : null}
                        alt="profile-image"
                        size="xs" // Establecer el tamaño como extra pequeño
                        // sx={{ cursor: "pointer" }}
                        sx={({ palette: { dark, white } }) => ({
                          color: light ? white.main : dark.main,
                        })}
                      />
                      <SoftTypography
                        variant="button"
                        fontWeight="medium"
                        color={light ? "white" : "dark"}
                      >
                        Cerrar Sesión
                      </SoftTypography>
                    </IconButton>
                  </Link>
                </>
              )}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
                style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
                style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
              >
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
                style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
      <Dialog
        open={modalOpen}
        // onClose={setCerrarModal}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent dividers>
          <SoftBox p={2}>
            <Stack direction="row" spacing={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>¿Deseas cerrar la sesión?&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftButton color="primary" onClick={handleLogout}>Si</SoftButton>
              <SoftButton onClick={setCerrarModal}>No</SoftButton>
            </Stack>
          </SoftBox>
        </DialogContent>
        </>
      </Dialog>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  username: PropTypes.string,
};

export default DashboardNavbar;
