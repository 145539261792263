import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Modal } from "antd";
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton"
import SoftAvatar from "components/SoftAvatar";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Drawer, Space } from "antd";
import { Select, Input } from "antd";
import SoftInput from "components/SoftInput";
import { MenuItem, ListItemIcon } from "@mui/material";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Check from "@mui/icons-material/Check";
import { Editor } from '@tinymce/tinymce-react';
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import apiUrl_home from "home_domain";
import { UserContext } from "App";


const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const authorsTableData = {
  columns: [
    { name: "Imagen", align: "center" },
    { name: "Titulo", align: "left" },
    { name: "Fechacreacion", align: "center" },
    { name: "Categoria", align: "center" },
    { name: "Comentarios", align: "center" },
    { name: "Vista", align: "center" },
    { name: "opciones", align: "center" },
    // { name: "Acción", align: "center" },
  ],
};

function TableBlogs(id) {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState();
  const { numero } = useParams();
  const [editingData, setEditingData] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [entradilla, setEntradilla] = useState("");
  const [contenido, setContenido] = useState("");
  const [imagenFile, setImagen] = useState("");
  const [categoria, setCategoria] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [autor, setAutor] = useState("");
  const [introduccion, setIntroduccion] = useState("");
  const [autores, setAutores] = useState([]);
  const [guardarHabilitado, setGuardarHabilitado] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileAdd, setSelectedFileAdd] = useState(null);
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [initialData, setInitialData] = useState([]);
  
  const [Iddata, setIddata] = useState("");
  const { Option } = Select;
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchTableBlogs = async () => {
    try {
      const response = await fetch(apiUrl + "/api/posts/?format=json");
      const data = await response.json();
      const formattedData = data.map(item => {
        const categoriasString = item.categoria.map(cat => cat.nombre).join(", ");
        // Obtenemos la fecha en formato YYYY-MM-DD
        const registrodDate = new Date(item.created_at).toISOString().split("T")[0];
        const imagenData = <SoftAvatar src={`${apiUrl}${item.imagen}`} alt={item.imagen} size="sm" variant="rounded" />;
        const opcionesData = (
          <>
            <IconButton
              onClick={() => handleEdit(item)}
              aria-label="Editar"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(item.id)}
              aria-label="Eliminar"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
        const VistaPrevia = (
          <>
            <Link to={`${apiUrl_home}/blogs/${item.slug}`} target="_blank">
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong>Ver</strong>
              </SoftTypography>
            </Link>
          </>
        );
        return {
          id: item.id,
          Imagen: imagenData,
          Titulo: item.titulo,
          Categoria: categoriasString,
          Fechacreacion: registrodDate, // Aquí asignamos la fecha formateada
          //tipoarbol: item.operador.date_of_birth,
          Vista: VistaPrevia,
          opciones: opcionesData,
          Comentarios: item.comentarios_count.toString(),
        };
      });
      setCounterData(formattedData);
      setInitialData(formattedData);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchTableBlogs();
    // fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/categorias/`);
      const data = await response.json();
      setCategorias(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchAutores = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/autor/`);
      const data = await response.json();
      setAutores(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleEdit = (data) => {
    console.log("datos", data)
    setSize("large");
    setIddata(data.id);
    setEditingData(data);
    setTitulo(data.titulo);
    setEntradilla(data.entradilla);
    setContenido(data.contenido);
    setImagen(data.imagen);
    const categorias = data.categoria.map(cat => cat.id);
    setCategoria(categorias);
    setAutor(data.autor.user.id);
    setIntroduccion(data.introduccion);
    setOpen(true);
    fetchCategorias();
    fetchAutores();
  };

  const handleSaveChanges = async () => {
    const updatedContenido = editorRef.current.getContent();
    setContenido(updatedContenido);    
    try {
      // const updatedData = {
      //   ...editingData,
      //   id: Iddata,
      //   titulo,
      //   entradilla,
      //   contenido,
      //   categoria,
      //   imagen: imagenFile,
      // };
      const csrftoken = getCookie("csrftoken");
      // console.log("updatedData", updatedData.imagen);
      // formData.append("imagen", updatedData.imagen);
      const formData = new FormData();
      formData.append("imagen", imagenFile);
      formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      formData.append("titulo", titulo);
      formData.append("entradilla", entradilla);
      formData.append("introduccion", introduccion);
      formData.append("contenido", contenido);
      formData.append("categoria", JSON.stringify(categoria) );
      formData.append("autor", autor);
      const response = await fetch(`${apiUrl}/api/post/edit/${Iddata}`, {
        method: "PUT",
        body: formData,
        headers: {
          "X-CSRFToken": csrftoken,
        },
      });
      if (response.ok) {
        console.log("Datos actualizados exitosamente");
        fetchTableBlogs(); // Actualiza la tabla después de editar
      } else {
        console.error("Error al actualizar los datos");
      }
      fetchAutores();
      fetchTableBlogs();
      setIntroduccion("");
    } catch (error) {
      console.error("Error al enviar la solicitud de actualización:", error);
    }
    setOpen(false);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar la imagen?" + id);
    if (confirmDelete) {
      fetch(apiUrl + `/api/post/delete/${id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchTableBlogs();
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const handleEditorChangeDebounced = debounce((content) => {
    setContenido(content);
    setGuardarHabilitado(true);
  }, 5000)

  const handleEditorChange = (evt, editor) => {
    handleEditorChangeDebounced(editor.getContent());
  };

  const handleEditorAddDebounced = debounce((content) => {
    setContenido("");
  }, 5000)

  const handleEditorAddChange = (evt, editor) => {
    handleEditorAddDebounced(editor.getContent());
  };

  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      setImagen(file);
      setEditingData(true);
    };
    input.click();
  };

  const handleDoubleClickAdd = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setSelectedFileAdd(file)
      setImagen(file);
    };
    input.click();
  };

  const handleAddClick = () => {
    fetchCategorias();
    fetchAutores();
    setShowAddDrawer(true);
    setTitulo("");
    setEntradilla("");
    setIntroduccion("");
    setContenido("");
    // setImagen("");
    setCategoria("");
    // setCategorias([])
    setGuardarHabilitado("");
    setSelectedFileAdd(null);
  };

  const handleCloseAddDrawer = () => {
    setShowAddDrawer(false);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileAdd(file);
  };

  const handleSaveNew = async () => {
    const updatedContenido = editorRef.current.getContent();
    setContenido(updatedContenido);
    
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("imagen", selectedFileAdd);
      formDataAdd.append("csrfmiddlewaretoken", csrftoken);
      formDataAdd.append("titulo", titulo);
      formDataAdd.append("entradilla", entradilla);
      formDataAdd.append("introduccion", introduccion);
      formDataAdd.append("contenido", contenido);
      formDataAdd.append("categoria", JSON.stringify(categoria));
  
      const response = await fetch(`${apiUrl}/api/post/add`, {
        method: "POST",
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
        },
      });
      const entries = formDataAdd.entries();
      for (const pair of entries) {
        console.log(pair[0] + ', ' + pair[1]); 
      }
      if (response.ok) {
        console.log("Datos agregados exitosamente");
        fetchTableBlogs(); // Actualiza la tabla después de agregar datos
        setCategorias([]);
      } else {
        console.error("Error al agregar los datos");
      }
    } catch (error) {
      console.error("Error al enviar la solicitud de agregar:", error);
    }
    setShowAddDrawer(false);
    setIntroduccion("");
  };

  useEffect(() => {
    const filteredData = initialData.filter(row => {
      const nombre = row.Titulo.toString().toLowerCase();
      return nombre.includes(searchText);
    });
    setCounterData(filteredData);
  }, [searchText, initialData]);

  const handleConsecutivoChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    
    const filteredData = initialData.filter(row => {
      const nombre = row.Titulo.toString().toLowerCase();
      return nombre.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  const handleClearConsecutivo = () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };


  const renderFilters = () => (
    <div>
      <Input
        placeholder="Titulo"
        onChange={(event) => handleConsecutivoChange(event)}
        onClear={handleClearConsecutivo}
        style={{ width: 200, margin: '0 10px' }}
      />
    </div>
  );

  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Blogs</SoftTypography>
            </SoftBox>
            <IconButton
                onClick={handleAddClick}
                sx={{
                  position: "absolute",
                  right: 100,
                  top: 15,
                }}
                aria-label="Agregar"
                style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
              >
                <AddIcon />
            </IconButton>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Filtro por: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{renderFilters()}
              <Table columns={columns} rows={counterData} />
            </SoftBox>
          </Card>
        </SoftBox>
      </DashboardTable>
      <Drawer
        title={`Editar ${size} `}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        footer={
          <Space>
            <SoftButton onClick={handleCloseDrawer}>Cancelar</SoftButton>
            <SoftButton disabled={!guardarHabilitado} type="primary" color="primary" onClick={handleSaveChanges}>Guardar</SoftButton>
          </Space>
        }
      >
        {editingData && (
          <div>&nbsp;&nbsp;
            <form encType="multipart/form-data">
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Imagen</strong>
            </SoftTypography>
            <SoftBox onDoubleClick={handleDoubleClick} onChange={setSelectedFile} component="div" width="100%" pt={3}>
              {selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt={selectedFile.name} style={{ width: '100%' }} />
              ) : (
                <img src={`${apiUrl}${imagenFile}`} alt={imagenFile} style={{ width: '100%' }} />
              )}
            </SoftBox>
            {imagenFile != null ? "Dar doble click para cambiar la imagen." : ""}
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong><br></br><br></br></strong>
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Título</strong>
            </SoftTypography>
            <SoftInput
              label="Título"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              fullWidth
            />&nbsp;&nbsp;
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Entradilla</strong>
            </SoftTypography>
            <SoftInput
              label="Entradilla"
              value={entradilla}
              onChange={(e) => setEntradilla(e.target.value)}
              fullWidth
            />&nbsp;
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Introducción</strong>
            </SoftTypography>
            <SoftInput
              label="Introducción"
              value={introduccion}
              onChange={(e) => setIntroduccion(e.target.value)}
              fullWidth
              // multiline
              minRows={3}
              maxRows={10}
            />&nbsp;
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Contenido</strong>
            </SoftTypography>
            <Editor
              apiKey='jxosl51ohi4i70w2iwqbk3usgc753u2x2wi7ic3bgg4i1hkg'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={contenido}
              onEditorChange={handleEditorChange}
              init={{
                height: 500,
                // menubar: true,
                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker  ',
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | addcomment showcomments | spellcheckdialog a11ycheck | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                // tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                mergetags_list: [
                  { value: 'First.Name', title: 'First Name' },
                  { value: 'Email', title: 'Email' },
                ],
                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            {/* <button onClick={log}>Log editor content</button> */}
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Categorías</strong>
            </SoftTypography>
            <Select
              mode="multiple"
              showSearch
              style={{ width: "100%", maxHeight: "300px" }} // Ajusta la altura máxima aquí
              dropdownStyle={{ maxHeight: "200px" }} // Ajusta la altura del menú desplegable aquí
              placeholder="Selecciona una categoría"
              onChange={setCategoria} // Actualiza el estado de la categoría seleccionada
              value={categoria} // Asigna el valor seleccionado
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {categorias.map((cat) => (
                <Option key={cat.id} value={cat.id}>
                  {cat.nombre}
                </Option>
              ))}
            </Select>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Autor</strong>
            </SoftTypography>
            <Select
              // mode="multiple"
              showSearch
              style={{ width: "100%", maxHeight: "300px" }} // Ajusta la altura máxima aquí
              dropdownStyle={{ maxHeight: "200px" }} // Ajusta la altura del menú desplegable aquí
              placeholder="Selecciona un autor"
              onChange={setAutor} // Actualiza el estado de la categoría seleccionada
              value={autor} // Asigna el valor seleccionado
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {autores.map((cat) => (
                <Option key={cat.id} value={cat.user.id}>
                  {cat.user.username}
                </Option>
              ))}
            </Select>
            </form>
          </div>
        )}
      </Drawer>
      {/* <SoftButton onClick={handleAddClick}>Agregar</SoftButton> */}
      {/* 
      
      
      
      Agregar Drawer
      
      
      
      */}
      <Drawer
        title="Agregar Datos"
        placement="right"
        onClose={handleCloseAddDrawer}
        visible={showAddDrawer}
        width={500}
        footer={
          <Space>
            <SoftButton onClick={handleCloseAddDrawer}>Cancelar</SoftButton>
            <SoftButton type="primary" onClick={handleSaveNew}>Guardar</SoftButton>
          </Space>
        }
      >
        <form encType="multipart/form-data">
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Imagen</strong>
            </SoftTypography>
            <SoftBox onDoubleClick={handleDoubleClickAdd} onChange={handleFileChange} component="div" width="100%" pt={3}>
            {selectedFileAdd ? (
              <img src={URL.createObjectURL(selectedFileAdd)} alt={selectedFileAdd.name} style={{ width: '100%' }} />
            ) : (
              <img src={rocketWhite} alt={rocketWhite} style={{ width: '100%' }} />
            )}
          </SoftBox>
              Dar doble click para cambiar la imagen.
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong><br></br><br></br></strong>
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Título</strong>
            </SoftTypography>
            <SoftInput
              label="Título"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
              fullWidth
            />&nbsp;&nbsp;
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Entradilla</strong>
            </SoftTypography>
            <SoftInput
              label="Entradilla"
              value={entradilla}
              onChange={(e) => setEntradilla(e.target.value)}
              fullWidth
            />&nbsp;
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Introducción</strong>
            </SoftTypography>
            <SoftInput
              label="Introducción"
              value={introduccion}
              onChange={(e) => setIntroduccion(e.target.value)}
              fullWidth
              // multiline
              minRows={3}
              maxRows={10}
            />&nbsp;
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Contenido</strong>
            </SoftTypography>
            <Editor
              apiKey='jxosl51ohi4i70w2iwqbk3usgc753u2x2wi7ic3bgg4i1hkg'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={contenido}
              onEditorChange={handleEditorAddChange}
              init={{
                height: 500,
                // menubar: true,
                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker  ',
                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | addcomment showcomments | spellcheckdialog | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                tinycomments_mode: 'embedded',
                tinycomments_author: 'Author name',
                mergetags_list: [
                  { value: 'First.Name', title: 'First Name' },
                  { value: 'Email', title: 'Email' },
                ],
                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            {/* <button onClick={log}>Log editor content</button> */}
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>Categorías</strong>
            </SoftTypography>
            <Select
              mode="multiple"
              placeholder="Selecciona una categoría"
              style={{ width: '100%' }}
              // value={categoria}
              onChange={(values) => setCategoria(values)}
            >
              {categorias.map(cat => (
                <Select.Option key={cat.id} value={cat.id}>
                  {cat.nombre}
                </Select.Option>
              ))}
            </Select>
            </form>
      </Drawer>
    </>
  );
}

export default TableBlogs;
