import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';

const Mapa = ({ latitud, longitud }) => {
  const [position, setPosition] = useState([latitud, longitud]);

  useEffect(() => {
    setPosition([parseFloat(latitud), parseFloat(longitud)]);
  }, [latitud, longitud]);

  return (
    <MapContainer center={position} zoom={13} style={{ height: '400px' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors'
      />
      {latitud && longitud && (
        <Marker position={position}>
          <Popup>Ubicación</Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

Mapa.propTypes = {
  latitud: PropTypes.number.isRequired,
  longitud: PropTypes.number.isRequired,
};

export default Mapa;
