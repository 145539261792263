import React, { useState, useEffect, useMemo, createContext, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import queryString from 'query-string';

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import apiUrl from "domain";
import apiUrl_home from "home_domain";
import ArbolSeguimiento from "layouts/arbol";
import InfoSeguimiento from "layouts/arbol/seguimientos";
import ListaBlogs from "layouts/blogs";
import Seguimientos from "layouts/seguimientos";
import axios from "axios";
import "font.css";
import { Fab } from "@mui/material";
import { WhatsApp } from "@mui/icons-material"; 

export const UserContext = createContext();

export default function App() {

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [user_id, setUserId] = useState();
  const [roleUser, setRoleUser] = useState(null);
  const [Logo, setLogo] = useState();
  const [numero, setNumero] = useState();
  const [texto, setTexto] = useState();

  const fetchWhatsapp = async () => {
    try {
      const response = await fetch(apiUrl + "/api/whatsapp/?format=json");
      const data = await response.json();
      setNumero(data[0].telefono)
      setTexto(data[0].texto)
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  }

  const fetchQuienes = async () => {
    try {
      // const response = await fetch(apiUrl + "/api/logo/?format=json");
      const response = await fetch(apiUrl + "/api/footer/?format=json");
      const data = await response.json();
      console.log('logo', data[0].imagen_footer);
      setLogo(data[0].imagen_footer);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const getCookieValue = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Verificar si la cookie comienza con el nombre buscado
        if (cookie.startsWith(name + '=')) {
            // Devolver el valor de la cookie
            return cookie.substring(name.length + 1);
        }
    }
    // Si no se encuentra la cookie, devolver null
    return null;
  };  

  const fetchUserProfile = async (token_id) => {
    // const token_id = getCookieValue('token');
    try {
      const csrftoken = getCookieValue(";csrftoken");
      const response = await axios.post(
        apiUrl + "/api/authentication/",
        { token_id },
        { headers: { "X-CSRFToken": csrftoken } }
      );
      setUserId(response.data.user_id);
      setRoleUser(response.data.rol);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    const token_id = getCookieValue('token');
    fetchUserProfile(token_id);
    fetchQuienes();
    fetchWhatsapp();
  }, []);
  const WhatsAppButton = () => {
    const openWhatsApp = () => {
      window.open(`https://api.whatsapp.com/send/?phone=%2B${numero}&text=${texto}`, "_blank");
    };
  
    return (
      <Fab
        color="primary"
        aria-label="whatsapp"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
        onClick={openWhatsApp}
        id="whatsapp-button"
      >
        <WhatsApp fontSize="large" />
      </Fab>
    );
  };
  useEffect(() => {
    const token_id = getCookieValue('token');
    if (token_id){
      fetchUserProfile(token_id);
    } else {
      window.location.href = `${apiUrl_home}`;
    }
    // Obtener los parámetros de la URL
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.user_id) {
      // Guardar los valores en el local storage
      localStorage.setItem('user_id', queryParams.user_id);
      localStorage.setItem('token', queryParams.token);
      // Guardar los valores en el session storage
      sessionStorage.setItem('user_id', localStorage.getItem("user_id"));
      sessionStorage.setItem('token', queryParams.token);
    }
  }, []);
  
  // const user_id = localStorage.getItem("user_id");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });
    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return (
    <>
      <UserContext.Provider value={user_id}>
      {user_id && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              {/* {user_id && ( */}
              {/* <Sidenav
                color={sidenavColor}
                brand={`${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`}
                brandName="VeᏡdeSer"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              /> */}
              {roleUser == "Administrador" && (
              <Sidenav
                color={sidenavColor}
                brand={Logo ? `${apiUrl}${Logo}` : `${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`}
                // brandName="VeᏡdeSer"
                routes={routes.filter(route => route.rol === "Administrador" || route.rol === "All")}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                sx={{ width: "9rem" }}
              />
            )}
            {roleUser == "Operador" && (
              <Sidenav
                color={sidenavColor}
                brand={Logo ? `${apiUrl}${Logo}` : `${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`}
                // brandName="VeᏡdeSer"
                routes={routes.filter(route => route.rol === "Operador" || route.rol === "All")}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                sx={{ width: "9rem" }}
              />
            )}
            {roleUser == "Usuario" && (
              <Sidenav
                color={sidenavColor}
                brand={Logo ? `${apiUrl}${Logo}` : `${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`}
                // brandName="VeᏡdeSer"
                routes={routes.filter(route => route.rol === "Usuario" || route.rol === "All")}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                sx={{ width: "9rem" }}
              />
            )}
            {roleUser == "SuperUser" && (
              <Sidenav
                color={sidenavColor}
                brand={Logo ? `${apiUrl}${Logo}` : `${apiUrl_home}/material-kit-react/static/media/favicon.c08f1ff6c4354a9fb550.ico`}
                // brandName="VeᏡdeSer"
                routes={routes.filter(route => route)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                sx={{ width: "9rem" }}
              />
            )}
              {/* )} */}
              <Configurator />
              {/* {configsButton} */}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/arbol/seguimientos/:numero" element={<ArbolSeguimiento />} />
            <Route path="/arbol/seguimiento/:numero" element={<InfoSeguimiento />} />
            <Route path="/blogs" element={<ListaBlogs />} />
            <Route path="/seguimientos/:arbol_id/:latitud/:longitud" element={<Seguimientos />} />
            {/* <Route path="/" element={<Home />} /> */}
          </Routes>
          <WhatsAppButton /> {/* Agrega el botón de WhatsApp */}
        </ThemeProvider>
      )}
      </UserContext.Provider>
      <style>
        {`
          @media screen and (max-width: 600px) {
            #whatsapp-button {
              display: block;
              bottom: "50px",
            }
          }
        `}
      </style>
    </>
  );
}
