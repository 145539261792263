import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from 'antd';
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton"
import SoftAvatar from "components/SoftAvatar";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useNavigate, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Delete, Add, Save } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Drawer, Input, Upload, Space, Select, DatePicker } from "antd";
import SoftInput from "components/SoftInput";
import axios from "axios";
import Mapa from "layouts/arbol/data/mapa";
import { UserContext } from "App";


const authorsTableData = {
  columns: [
    { name: "Consecutivo", align: "center" },
    { name: "Imagen", align: "center" },
    { name: "Nickname", align: "left" },
    { name: "fechasiembra", align: "center" },
    { name: "Finca", align: "center" },
    { name: "Fecharegistro", align: "left" },
    { name: "tipoarbol", align: "left" },
    { name: "NoSeguimientos", align: "center" },
    { name: "Seguimientos", align: "center" },
    { name: "opciones", align: "center" },
    // { name: "Acción", align: "center" },
  ],
};

function TableArboles() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [drawerVisibleAdd, setDrawerVisibleAdd] = useState(false);
  const [seguimientoAdd, setSeguimientoAdd] = useState(false);
  const [drawerVisibleEdit, setDrawerVisibleEdit] = useState(false);
  const [size, setSize] = useState();

  // MTM
  const [operadores, setOperadores] = useState([]);
  const [propietarios, setPropietarios] = useState([]);
  const [TiposArboles, setTiposArboles] = useState([]);
  const [potreros, setPotreros] = useState([]);
  const [fincas, setFincas] = useState([]);

  //Add
  const [newNickName, setNewNickname] = useState();
  const [newImagen, setNewImagen] = useState("");
  const [newOperador, setNewOperador] = useState();
  
  const [newLatitud, setNewLatitud] = useState();
  const [newLongitud, setNewLongitud] = useState();
  const [newPotrero, setNewPotrero] = useState([]);
  const [newFinca, setNewFinca] = useState([]);
  const [newTipo, setNewTipo] = useState([]);
  const [newFechaSiembra, setNewFechaSiembra] = useState(new Date());
  const [newPropietario, setNewPropietario] = useState();

  //edit

  const [latitud, setLatitud] = useState();
  const [longitud, setLongitud] = useState();

  const [editNickName, setEditNickname] = useState();
  const [editImagen, setEditImagen] = useState("");
  const [editOperador, setEditOperador] = useState();
  const [editLatitud, setEditLatitud] = useState();
  const [editLongitud, setEditLongitud] = useState();
  const [editPotrero, setEditPotrero] = useState([]);
  const [editFinca, setEditFinca] = useState([]);
  const [editTipo, setEditTipo] = useState([]);
  const [editFechaSiembra, setEditFechaSiembra] = useState(new Date());
  const [editPropietario, setEditPropietario] = useState();
  const [arbolId, setArbolId] = useState();
  const [editandoFecha, setEditandoFecha] = useState(false);

  const [editingArbol, setEditingArbol] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");

  // new seguimiento
  const [newoperador, setnewoperador] = useState("")
  const [newfecha_registro, setnewfecha_registro] = useState([]);
  const [newestado_arbol, setnewestado_arbol] = useState(null);
  const [newaltura, setnewaltura] = useState(null);
  const [newatencion_prioritaria, setnewatencion_prioritaria] = useState([]);
  const [newcondicion, setnewcondicion] = useState([]);
  const [newarbol, setnewarbol] = useState([]);
  const [atencion_prioritarias, setatencion_prioritarias] = useState([]);
  const [condiciones, setcondiciones] = useState([]);
  const [arboles, setarboles] = useState([]);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState([]); // Datos originales
  const [searchText, setSearchText] = useState('');

  const { RangePicker } = DatePicker;

  const [errorNickName, setErrorNickName] = useState('');
  const [errorOperador, setErrorOperador] = useState('');
  const [errorFinca, setErrorFinca] = useState('');
  const [errorLongitud, setErrorLongitud] = useState('');
  const [errorLatitud, setErrorLatitud] = useState('');
  const [errorPotrero, setErrorPotrero] = useState('');
  const [errorTipo, setErrorTipo] = useState('');
  const [errorFechaSiembra, setErrorFechaSiembra] = useState('');
  const [errorPropietario, setErrorPropietario] = useState('');

  

  const handleEdit = (data) => {
    console.log('data', data);
    setSize("large");
    setEditingArbol(data);
    setDrawerVisibleEdit(true);
    setEditNickname(data.nickname);
    setEditImagen(data.imagen_principal);
    setEditFinca(data.finca.id);
    if (data.operador.id) {
      setEditOperador(data.operador.id);
    } else {
      setEditOperador(1);
    }
    
    setEditLatitud(data.longitud);
    setEditLongitud(data.latitud);
    setEditPotrero(data.potrero.id);
    setEditTipo(data.tipo_arbol.id);
    const editDate = new Date(data.fecha_siembra).toISOString().split('T')[0];
    setEditFechaSiembra(editDate);
    if (data.propietario.id) {
      setEditPropietario(data.propietario.id);
    } else {
      setEditPropietario(1);
    }
    
    setArbolId(data.id)
    setLatitud(data.latitud);
    setLongitud(data.longitud);    
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const showModal = (data) => {
      setIsModalOpen(true);
      setmodalData(data);
  };

  const handleOk = () => {
      setIsModalOpen(false);
  };

  const handleCancel = () => {
      setIsModalOpen(false);
  };
  const fetchTableProfile = async () => {
    try {
      const response = await fetch(apiUrl + "/api/arboles-all/?format=json");
      const data = await response.json();
      const formattedData = data.map(item => {
        const registrodDate = new Date(item.fecha_registro).toISOString().split('T')[0];
        const siembraodDate = new Date(item.fecha_siembra).toISOString().split('T')[0];
        const nombreData = <SoftTypography type="primary" onClick={() => showModal([item])}>{item.nickname}</SoftTypography>;
        const imagenData = <SoftAvatar src={`${apiUrl}${item.imagen_principal}`} alt={item.nickname} size="sm" variant="rounded" />;
        const opcionesData = (
          <>
            <IconButton
              onClick={() => handleEdit(item)}
              aria-label="Editars"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(item)}
              aria-label="Eliminar"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
        const agregarSeguimiento = (
          <>
            <IconButton
            style={{
              position: "abslolute",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
              borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
              width: "40px", // Ajusta el ancho según tus necesidades
              height: "40px" // Ajusta la altura según tus necesidades
            }}
            onClick={() => openAddSeguimiento(item.id)}
            >
              <Add />&nbsp;
            </IconButton>
            {item.seguimientos_count > 0 ? (
              <>
                <Link to={`/seguimientos/${item.id}/${item.latitud}/${item.longitud}`} target="_blank">
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    <strong>Ver</strong>
                  </SoftTypography>
                </Link>
              </>
            ) : (
              <>
                <Link>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                  </SoftTypography>
                </Link>
              </>
            )}
          </>
        );
        return {
          id: item.id,
          Consecutivo: item.consecutico,
          Nickname: item.nickname,
          Fecharegistro: registrodDate, // Aquí asignamos la fecha formateada
          Finca: item.finca.nombre,
          tipoarbol: item.tipo_arbol.nombre,
          fechasiembra: siembraodDate,
          Imagen: imagenData,
          NoSeguimientos: item.seguimientos_count.toString(),
          Seguimientos: agregarSeguimiento,
          opciones: opcionesData,
          tipoarbol_id: item.tipo_arbol.id,
        };
      });
      setCounterData(formattedData);
      setInitialData(formattedData);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchPrioridad = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/prioridad/`);
      const data = await response.json();
      setatencion_prioritarias(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchFinca = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/finca/`);
      const data = await response.json();
      setFincas(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchCondicion = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/condicion/`);
      const data = await response.json();
      setcondiciones(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  useEffect(() => {
    fetchTableProfile();
    fetchPrioridad();
    fetchCondicion();
    fetchFinca();
  }, []);

  const fetchOperadores = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/operadores/`);
      const data = await response.json();
      setOperadores(data);
      setnewoperador
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchPotreros = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/potreros/`);
      const data = await response.json();
      setPotreros(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fecthTipoArboles = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/tipo-arboles/`);
      const data = await response.json();
      setTiposArboles(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fecthPropietarios = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/propietarios/`);
      const data = await response.json();
      setPropietarios(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  useEffect(() => {
    fetchOperadores();
    fetchPotreros();
    fecthTipoArboles();
    fecthPropietarios();
    fetchTableProfile();
  }, []);

  const handleSaveNewArbol = async () => {
    if (
      !newNickName ||
      !newOperador ||
      !newFinca ||
      !newPotrero ||
      !newTipo ||
      !newFechaSiembra ||
      !newPropietario
    ) {
      // Si alguno de los campos obligatorios está vacío, muestra un mensaje de error
      alert('Por favor, completa todos los campos obligatorios.');
      return;
    }
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("nickname", newNickName);
      formDataAdd.append("operador", newOperador);
      formDataAdd.append("fecha_siembra", new Date(newFechaSiembra).toISOString().split('T')[0],);
      formDataAdd.append("latitud", newLatitud);
      formDataAdd.append("longitud", newLongitud);
      formDataAdd.append("potrero", newPotrero);
      formDataAdd.append("tipo_arbol", newTipo);
      formDataAdd.append("finca", newFinca);
      formDataAdd.append("propietario", newPropietario);
      formDataAdd.append('imagen_principal', newImagen);
      //formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/arbol/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchTableProfile();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    setDrawerVisibleAdd(false);
    fetchOperadores();
    fetchPotreros();
    fecthTipoArboles();
    fecthPropietarios();
    fetchFinca();
    clearData();
  };
  
  const handleSeguimientoClick = (itemId) => {
    window.location.href = `/arbol/seguimientos/${itemId}`;
    // navigate(`${apiUrl}/arbol/seguimiento/${itemId}`); // Navigate to TableSeguimiento with itemId
  };

  const openAddDrawer = () => {
    setDrawerVisibleAdd(true);
    setSize("large");
  };

  const loadDataInitial = () => {
    fetchOperadores();
    fetchPotreros();
    fecthTipoArboles();
    fecthPropietarios();
  };

  const clearData = () => {
    setNewNickname("");
    setNewImagen("");
    setNewOperador(null);
    setNewLatitud("");
    setNewLongitud("");
    setNewPotrero(null);
    setNewTipo(null);
    setNewFechaSiembra("");
    setNewPropietario(null);
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar el arbol" + item.nickname + "?");
    if (confirmDelete) {
      fetch(apiUrl + `/api/arbol/delete/${item.id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchTableProfile();
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const handleSaveChange = async () => {
    try {
      const formData = new FormData();
      formData.append('imagen', selectedFile);
      formData.append('nickname', editNickName);
      formData.append('operador', editOperador);
      formData.append('fecha_siembra', new Date(editFechaSiembra).toISOString().split('T')[0]);
      formData.append('longitud', editLongitud);
      formData.append('latitud', editLatitud);
      formData.append('potrero', editPotrero);
      formData.append('finca', editFinca);
      formData.append('tipo_arbol', editTipo);
      formData.append('propietario', editPropietario);
      const response = await axios.post(
        `${apiUrl}/api/arbol/update/${arbolId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchTableProfile();
      setDrawerVisibleEdit(false);
      setSelectedFile("");
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      fetchOperadores();
      fetchPotreros();
      fecthTipoArboles();
      fecthPropietarios();
      clearData();
    }
  };

  const handleSaveNewSeguimiento = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("operador_id", newoperador);
      formDataAdd.append("fecha_registro", new Date(newfecha_registro).toISOString().split('T')[0],);
      formDataAdd.append("estado_arbol", newestado_arbol);
      formDataAdd.append("altura", newaltura);
      formDataAdd.append("atencion_prioritaria_id", newatencion_prioritaria);
      formDataAdd.append("condicion_id", newcondicion);
      formDataAdd.append("arbol_id", newarbol);
      //formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/seguimiento/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchTableProfile();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    setSeguimientoAdd(false);
    fetchTableProfile();
    fetchOperadores();
    fetchPrioridad();
    fetchCondicion();
  };

  const openAddSeguimiento = (arbol_id) => {
    setnewarbol(arbol_id);
    setSize("large");
    setSeguimientoAdd(true);
  };

  const handleChangeNickname = (event) => {
    const inputValue = event.target.value;
    // Expresión regular que permite solo letras y números
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(inputValue)) {
      setNewNickname(inputValue);
    }
  };

  const closeEditArbol = (arbol_id) => {
    setDrawerVisibleEdit(false);
    setLongitud(null);
    setLatitud(null);
  };
  //filtros

  // Handlers para los cambios en los filtros
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0].startOf('day').toISOString();
      const endDate = dates[1].endOf('day').toISOString();
      const filteredData = initialData.filter(row => {
        const fechaSiembra = new Date(row.fechasiembra).toISOString();
        return fechaSiembra >= startDate && fechaSiembra <= endDate;
      });
  
      // Actualizar los datos de la tabla con los resultados filtrados
      setCounterData(filteredData);
    } else {
      // Si no se selecciona un rango completo, mostrar todos los datos
      setCounterData(initialData);
    }
  };

  const handleConsecutivoChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    const filteredData = initialData.filter(row => {
      const consecutivo = row.Consecutivo.toString().toLowerCase();
      return consecutivo.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  useEffect(() => {
    const filteredData = initialData.filter(row => {
      const consecutivo = row.Consecutivo.toString().toLowerCase();
      return consecutivo.includes(searchText);
    });
    setCounterData(filteredData);
  }, [searchText, initialData]);

  // Llamar a handleConsecutivoChange también cuando el campo de entrada se vacíe
  const handleClearConsecutivo = () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };
  const [searchValue, setSearchValue] = useState('');

  const handleTipoArbolChange = (selectedTipoArbolId) => {
    // Si se selecciona la opción "Limpiar filtro"
    if (selectedTipoArbolId === "") {
      setCounterData(initialData);
    } else {
      // Si se selecciona un tipo de árbol
      const filteredData = initialData.filter(row => row.tipoarbol_id === selectedTipoArbolId);
      setCounterData(filteredData);
    }
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };


  const handleFechaEdit = () => {
    setEditandoFecha(true); // Activar la edición de la fecha
  };

  const renderFilters = () => (
    <div>
      <RangePicker placeholder="Fecha de Siembra" onChange={handleDateChange} />
      <Input
        placeholder="Consecutivo"
        onChange={(event) => handleConsecutivoChange(event)}
        onClear={handleClearConsecutivo}
        style={{ width: 200, margin: '0 10px' }}
      />
      <Select
        showSearch
        placeholder="Tipo de árbol"
        onChange={handleTipoArbolChange}
        style={{ width: 200 }}
        onSearch={onSearch}
        value={searchValue}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Select.Option key="limpiar" value="">Sin filtro</Select.Option>
        {TiposArboles.map(arbol => (
          <Select.Option key={arbol.id} value={arbol.id}>
            {arbol.nombre}
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Lista de Arboles</SoftTypography>
              <IconButton
              style={{
                position: "abslolute",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
              onClick={openAddDrawer}
            >
              <SoftTypography variant="h6">Agregar</SoftTypography> <Add />
            </IconButton>
            </SoftBox>
            <SoftBox
              style={{ display: 'flex',
                      justifyContent: 'center'
                    }}
            >
              {renderFilters()}
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={counterData} />
              
            </SoftBox>
          </Card>
        </SoftBox>
      </DashboardTable>
      <Drawer
        title="Nuevo Arbol"
        anchor="right"
        size={size}
        open={drawerVisibleAdd}
        
        onClose={() => setDrawerVisibleAdd(false)}
        footer={
          <Space>
            <SoftButton  onClick={() => setDrawerVisibleAdd(false)}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveNewArbol}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <Upload
                name="imagen"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => setNewImagen(file)}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setNewImagen(info.file.originFileObj);
                  }
                }}
              >
                {newImagen ? (
                  <img src={URL.createObjectURL(newImagen)} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  <img src={rocketWhite} alt="avatar" style={{ width: "100%" }} />
                )}
              </Upload>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong><br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>NickName&nbsp;&nbsp;</strong>
              Sin simbolos especiales ni espacios
              </SoftTypography>
              <SoftInput
                name="nickname"
                value={newNickName}
                onChange={handleChangeNickname} 
                fullWidth
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Operador</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un Operador"
                style={{ width: '100%' }}
                value={newOperador}
                onChange={(values) => setNewOperador(values)}
              >
                {operadores.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong><span style={{ color: 'red' }}>*&nbsp;</span>Finca</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un Operador"
                style={{ width: '100%' }}
                value={newFinca}
                onChange={(values) => setNewFinca(values)}
              >
                {fincas.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Longitud&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                step="any"
                name="longitud"
                value={newLongitud}
                onChange={(event) => setNewLongitud(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong>Latitud&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                step="any"
                name="latitud"
                value={newLatitud}
                onChange={(event) => setNewLatitud(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Potrero&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un potrero"
                style={{ width: '100%' }}
                value={newPotrero}
                onChange={(values) => setNewPotrero(values)}
              >
                {potreros.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Tipo de Arbol&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona una tipo"
                style={{ width: '100%' }}
                value={newTipo}
                onChange={(values) => setNewTipo(values)}
              >
                {TiposArboles.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Fecha de Siembra&nbsp;&nbsp;</strong>
              </SoftTypography>
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Siembra"
                    value={newFechaSiembra}
                    onChange={(event) => setNewFechaSiembra(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Propietario&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un Propietario"
                style={{ width: '100%' }}
                value={newPropietario}
                onChange={(values) => setNewPropietario(values)}
              >
                {propietarios.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
            </SoftBox>
          </form>
      </Drawer>
      {/* editar */}
      <Drawer
        title="Editar Arbol"
        anchor="right"
        size={size}
        open={drawerVisibleEdit}
        
        onClose={closeEditArbol}
        footer={
          <Space>
            <SoftButton  onClick={closeEditArbol}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveChange}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <Upload
                name="imagen"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => setSelectedFile(file)}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setSelectedFile(info.file.originFileObj);
                  }
                }}
              >
                {selectedFile ? (
                  <img src={URL.createObjectURL(selectedFile)} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  <img src={`${apiUrl}${editImagen}`} alt="avatar" style={{ width: "100%" }} />
                )}
              </Upload>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong><br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;NickName&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                // type="text"
                name="nickname"
                value={editNickName ? editNickName : ''}
                onChange={(event) => setEditNickname(event.target.value)} 
                fullWidth
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Finca</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un Operador"
                style={{ width: '100%' }}
                value={editFinca ? editFinca : 1}
                onChange={(values) => setEditFinca(values)}
              >
                {fincas.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Fecha de Siembra</strong>
              </SoftTypography>
              {!editandoFecha ? (
                <>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    <Icon color="primary" fontSize="small">
                      date_range 
                    </Icon>
                    &nbsp;&nbsp;&nbsp;&nbsp;{editFechaSiembra}&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftButton color="primary" onClick={handleFechaEdit}>Cambiar</SoftButton>
                </>
              ) : (
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Siembra"
                    value={editFechaSiembra}
                    onChange={(event) => setEditFechaSiembra(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <SoftButton color="info" onClick={() => setEditandoFecha(false)}>Cancelar</SoftButton>
                </>
              )}
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Operador</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un Operador"
                style={{ width: '100%' }}
                value={editOperador ? editOperador : 1}
                onChange={(values) => setEditOperador(values)}
              >
                {operadores.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Longitud&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                step="any"
                name="longitud"
                value={editLongitud}
                onChange={(event) => setEditLongitud(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Latitud&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                step="any"
                name="latitud"
                value={editLatitud}
                onChange={(event) => setEditLatitud(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Potrero&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un potrero"
                style={{ width: '100%' }}
                value={editPotrero ? editPotrero : 1}
                onChange={(values) => setEditPotrero(values)}
              >
                {potreros.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Tipo de Arbol&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona una tipo"
                style={{ width: '100%' }}
                value={editTipo ? editTipo : 1}
                onChange={(values) => setEditTipo(values)}
              >
                {TiposArboles.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Propietario&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                placeholder="Selecciona un Propietario"
                style={{ width: '100%' }}
                value={editPropietario ? editPropietario : 1}
                onChange={(values) => setEditPropietario(values)}
              >
                {propietarios.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
            </SoftBox>
            {openAddDrawer && longitud && latitud &&(
              <>
            <h1>Mapa</h1>
            <Mapa latitud={latitud} longitud={longitud} />
            </>
            )}
          </form>
      </Drawer>

      {/* nuevo seguimiento */}

      <Drawer
        title="Nuevo Seguimiento"
        anchor="right"
        size={size}
        open={seguimientoAdd}
        
        onClose={() => setSeguimientoAdd(false)}
        footer={
          <Space>
            <SoftButton  onClick={() => setSeguimientoAdd(false)}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveNewSeguimiento}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Operador</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newoperador}
                onChange={(values) => setnewoperador(values)}
              >
                {operadores.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Fecha de Registro&nbsp;&nbsp;</strong>
              </SoftTypography>
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Registro"
                    value={newfecha_registro}
                    onChange={(event) => setnewfecha_registro(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  {/* <SoftButton color="info" onClick={() => setEditandoFecha(false)}>Cancelar</SoftButton> */}
                </>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Altura en cms&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                name="altura"
                value={newaltura}
                onChange={(event) => setnewaltura(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Prioridad&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newatencion_prioritaria}
                onChange={(values) => setnewatencion_prioritaria(values)}
              >
                {atencion_prioritarias.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Condicion&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newcondicion}
                onChange={(values) => setnewcondicion(values)}
              >
                {condiciones.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              {/* <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Arbol&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newarbol}
                onChange={(values) => setnewarbol(values)}
              >
                {arboles.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nickname}
                  </Select.Option>
                ))}
              </Select> */}
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Estado&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                // type="text"
                placeholder="Observaciones 
                
                
                
                
                
                
                
                
                
                "
                name="estado_arbol"
                value={newestado_arbol}
                onChange={(event) => setnewestado_arbol(event.target.value)} 
                fullWidth
                rows={5}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
            </SoftBox>
          </form>
      </Drawer>
    </>
  );
}

export default TableArboles;
