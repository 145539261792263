import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from 'antd';
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton"
import SoftAvatar from "components/SoftAvatar";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";

const authorsTableData = {
  columns: [
    { name: "Imagen", align: "center" },
    { name: "Nickname", align: "left" },
    { name: "Fecharegistro", align: "left" },
    { name: "tipoarbol", align: "left" },
    { name: "fechasiembra", align: "center" },
    { name: "Seguimientos", align: "center" },
    { name: "opciones", align: "center" },
    // { name: "Acción", align: "center" },
  ],
};

function TableProfile() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState();
  const navigate = useNavigate();

  const showModal = (data) => {
      setIsModalOpen(true);
      setmodalData(data);
  };
  const handleOk = () => {
      setIsModalOpen(false);
  };
  const handleCancel = () => {
      setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchTableProfile = async () => {
      try {
        const response = await fetch(apiUrl + "/api/arboles/" + user_id + "?format=json");
        //console.log("response", response);
        const data = await response.json();
        const formattedData = data.map(item => {
          // Obtenemos la fecha en formato YYYY-MM-DD
          const registrodDate = new Date(item.fecha_registro).toISOString().split('T')[0];
          const siembraodDate = new Date(item.fecha_siembra).toISOString().split('T')[0];
          const nombreData = <SoftTypography type="primary" onClick={() => showModal([item])}>{item.nickname}</SoftTypography>;
          const imagenData = <SoftAvatar src={`${apiUrl}${item.imagen_principal}`} alt={item.nickname} size="sm" variant="rounded" />;
          const opcionesData = <SoftTypography type="primary" onClick={() => showModal([item])}>...</SoftTypography>;
          return {
            id: item.id,
            Nickname: item.nickname,
            Fecharegistro: registrodDate, // Aquí asignamos la fecha formateada
            tipoarbol: item.tipo_arbol.nombre,
            fechasiembra: siembraodDate,
            Imagen: imagenData,
            Seguimientos: item.seguimientos_count.toString(),
            opciones: opcionesData,
          };
        });
        setCounterData(formattedData);
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    };
    fetchTableProfile();
  }, []);
  
  const handleSeguimientoClick = (itemId) => {
    window.location.href = `/arbol/seguimientos/${itemId}`;
    // navigate(`${apiUrl}/arbol/seguimiento/${itemId}`); // Navigate to TableSeguimiento with itemId
  };

  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Mis Arboles</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={counterData} />
            </SoftBox>
          </Card>
        </SoftBox>
        {modalData &&(
          <Modal
              title={modalData.nickname}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <div>
                {modalData.map((data) => (
                  <Card key={data.id}>
                    <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                      {data.nickname}
                    </SoftTypography>
                    <SoftBox p={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                          <SoftBox display="flex" flexDirection="column" height="100%">
                            {/* <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                              Soft UI Dashboard
                            </SoftTypography> */}
                            <SoftBox mb={6}>
                              <SoftTypography variant="body2" color="text">
                                {data.nickname}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                Operador: {data.nickname}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                Tipo: {data.tipo_arbol.nombre}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                Tipo: {data.potrero.nombre}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                Fecha Siembra: {data.fecha_siembra}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                Longitud: {data.longitud}
                              </SoftTypography>
                              <SoftTypography variant="body2" color="text">
                                Latitud: {data.latitud}
                              </SoftTypography>
                            </SoftBox>
                            {data.seguimientos_count > 0 && (
                              <SoftButton color="primary" onClick={() => handleSeguimientoClick(data.id)}>
                                Seguimientos
                              </SoftButton>
                            )}
                            {/* <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
                          <SoftBox
                            height="100%"
                            display="grid"
                            justifyContent="center"
                            alignItems="center"
                            bgColor="red"
                            borderRadius="lg"
                            variant="gradient"
                          >
                            <SoftBox component="img" src={`${apiUrl}${data.imagen_principal}`} alt="rocket" width="100%" pt={3} />
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                  
                  
                ))}
              </div>
          </Modal>
        )}
      </DashboardTable>
    </>
  );
}

export default TableProfile;
