import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Pagination } from "@mui/material";

function Table({ columns, rows }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({ column: "", ascending: true });
  const itemsPerPage = 10;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSort = (columnName) => {
    setSortBy({
      column: columnName,
      ascending: sortBy.column === columnName ? !sortBy.ascending : true,
    });
  };

  const filteredRows = useMemo(() => {
    if (!searchValue) return rows;
  
    return rows.filter((row) => {
      return Object.values(row).some((value) => {
        if (Array.isArray(value)) {
          return value[1].toString().toLowerCase().includes(searchValue.toLowerCase());
        }
        return value.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
    });
  }, [searchValue, rows]);

  const sortedRows = useMemo(() => {
    if (!sortBy.column) return filteredRows;

    return [...filteredRows].sort((a, b) => {
      const valueA = a[sortBy.column];
      const valueB = b[sortBy.column];

      if (sortBy.ascending) {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
  }, [filteredRows, sortBy]);

  const totalPages = Math.ceil(sortedRows.length / itemsPerPage);

  const renderColumns = columns.map(({ name, align, width }, key) => {
    const fontWeightBold = "bold";
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        onClick={() => handleSort(name)}
        style={{ cursor: "pointer" }}
      >
        {name.toUpperCase()}
        {sortBy.column === name && (
          <span>{sortBy.ascending ? " ↑" : " ↓"}</span>
        )}
      </SoftBox>
    );
  });

  const renderRows = useMemo(() => {
    return sortedRows
      .slice((page - 1) * itemsPerPage, page * itemsPerPage)
      .map((row, key) => {
        const rowKey = `row-${key}`;

        const tableRow = columns.map(({ name, align }) => {
          let template;

          if (Array.isArray(row[name])) {
            template = (
              <SoftBox
                key={uuidv4()}
                component="td"
                p={1}
                borderBottom={
                  row.hasBorder
                    ? `${borderWidth[1]} solid ${light.main}`
                    : null
                }
              >
                <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
                  <SoftBox mr={2}>
                    <SoftAvatar
                      src={row[name][0]}
                      name={row[name][1]}
                      variant="rounded"
                      size="sm"
                    />
                  </SoftBox>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    sx={{ width: "max-content" }}
                  >
                    {row[name][1]}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          } else {
            template = (
              <SoftBox
                key={uuidv4()}
                component="td"
                p={1}
                textAlign={align}
                borderBottom={
                  row.hasBorder
                    ? `${borderWidth[1]} solid ${light.main}`
                    : null
                }
              >
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                  sx={{ display: "inline-block", width: "max-content" }}
                >
                  {row[name]}
                </SoftTypography>
              </SoftBox>
            );
          }

          return template;
        });

        return <TableRow key={rowKey}>{tableRow}</TableRow>;
      });
  }, [page, itemsPerPage, sortedRows, columns, light.main, borderWidth, sortBy]);

  return (
    <TableContainer>
      <MuiTable>
        <SoftBox component="thead">
          <TableRow>{renderColumns}</TableRow>
        </SoftBox>
        <TableBody>{renderRows}</TableBody>
      </MuiTable>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        shape="rounded"
        size="large"
        style={{ marginTop: 20 }}
      />
    </TableContainer>
  );
}

Table.defaultProps = {
  columns: [],
  rows: [{}],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
