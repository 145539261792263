import { useState, useEffect, useContext } from "react";
import SoftBox from "components/SoftBox";
import breakpoints from "assets/theme/base/breakpoints";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import apiUrl from "domain";
import axios from "axios";
import { UserContext } from "App";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function HeaderImage() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [nombreProfile, setnombreProfile] = useState(null);
  const [apellidoProfile, setapellidoProfile] = useState(null);
  const [usernameProfile, setusernameProfile] = useState(null);
  const [imagen, setImagen] = useState(null);
  const [editModeImagen, setEditModeImagen] = useState(false);
  const [imagenPerfil, setimagenPerfil] =  useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editModeInfo, setEditModeInfo] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const user_id = useContext(UserContext);
  const [nombreUsuario, setnombreUsuario] = useState('');

  const fetchBgImage = async () => {
    try {
      const response = await fetch(apiUrl + "/api/user/" + user_id + "/?format=json");
      const data = await response.json();
      console.log("data[0]", data);
      setnombreProfile(data[0].user.first_name);
      setapellidoProfile(data[0].user.last_name);
      setusernameProfile(data[0].user.username);
      setimagenPerfil(data[0].avatar);
      setnombreUsuario(data[0].user.username);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchBannerAdmin = async () => {
    try {
      const response = await fetch(apiUrl + "/api/banner-admin/?format=json");
      const data = await response.json();
      setImagen(data[0].imagen_encabezado);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const handleImagenSeleccionada = (evento) => {
    const archivo = evento.target.files[0];
    setSelectedFile(URL.createObjectURL(archivo));
  };

  const handleGuardarImagen = async () => {
    const formData = new FormData();
    formData.append("imagen_encabezado", imagen);
    formData.append("csrfmiddlewaretoken", csrfToken);
    try {
      formData.append("avatar", selectedFile);
      const response = await fetch(apiUrl + "/api/user/upload/encabezado-admin/", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRFToken": csrfToken
        }
      });
      if (response.ok) {        
        const data = await response.json();
        fetchBgImage();
        setEditModeImagen(false);
      } else {
        console.error("Failed to upload image");
      }
      fetchBannerAdmin();
    } catch (error) {
      console.error("Error saving avatar:", error);
    }
  };

  useEffect(() => {
    fetchBannerAdmin();
    fetchBgImage();
    // Lógica para obtener el token CSRF del backend
    fetch(apiUrl + "/api/get-csrf-token/")
      .then(response => response.json())
      .then(data => setCsrfToken(data.csrf_token))
      .catch(error => console.error("Error fetching CSRF token:", error));
  }, []);

  useEffect(() => {    
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setImagen(file);
      setEditModeImagen(true);
    };
    input.click();
  };

  const handleInputData = async () => {
    const formData = new FormData();
    formData.append('first_name', nombreProfile);
    formData.append('last_name', apellidoProfile);
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }
    const response = await axios.put(apiUrl + "/api/user/" + user_id + "/update/", formData);
    fetchBgImage();
    setEditModeInfo(false);
  };

  const handleCancelarEdicion = () => {
    setImagen(null);
    setEditModeImagen(false);
    fetchBannerAdmin();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar username={nombreUsuario}/>
      <SoftBox position="relative">
        <SoftBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: `${
              imagen instanceof File
                ? `url(${URL.createObjectURL(imagen)})`
                : `url(${apiUrl}${imagen})`
            }`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        >
          <input id="input-imagen" type="file" accept="image/*" onChange={handleImagenSeleccionada} style={{ display: 'none' }} />
          {editModeImagen ? (
            <>
              <IconButton
               variant="contained"
               color="info"
               aria-label="Guardar"
               style={{
                   position: 'absolute',
                   top: '40px',
                   right: '10px',
                   backgroundColor: "#ffffff",
                   borderRadius: "0.5rem",
                   width: "40px",
                   height: "40px",
               }}
              >
                <SaveIcon onClick={handleGuardarImagen} />
              </IconButton>
              <IconButton
                variant="contained"
                color="warning"
                aria-label="Cancelar"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: "#ffffff",
                  borderRadius: "0.5rem",
                  width: "40px",
                  height: "40px"
                }}
              >
               <CancelIcon onClick={handleCancelarEdicion} />
              </IconButton>
           </>
           ) : (
           <>
              <IconButton
                variant="contained"
                color="info"
                onClick={handleInputData}
                aria-label="Guardar"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: "#ffffff",
                  borderRadius: "0.5rem",
                  width: "40px",
                  height: "40px"
                }}
              > 
               <EditIcon onClick={handleClick} />
              </IconButton>
           </>
           )}
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default HeaderImage;
