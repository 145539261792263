import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import apiUrl from "domain";
import axios from "axios";
import { Drawer, Input, Upload, Space } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Delete, Add } from "@mui/icons-material";
import { UserContext } from "App";
import SinLogo from "assets/images/sin-logo.png"
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { useMediaQuery } from "@mui/material";

function FileUploadComponent() {
  const user_id = useContext(UserContext);
  const [dataGaleria, setdataGaleria] = useState([]);
  const [TituloEquipo, setTituloEquipo] = useState(null);
  const [textoEquipo, setTextoEquipo] = useState(null);
  const [idData, setidData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [perfilEdit, setPerfilEdit] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newPerfil, setNewPerfil] = useState({
    cargo: "",
    nombre: "",
    descripcion: "",
    imagen: null,
  });

  const isDesktop = useMediaQuery("(min-width: 960px)");

  const [file, setFile] = useState(null);

  const fetchQuienes = async () => {
    try {
      const response = await fetch(apiUrl + "/api/terminos/?format=json");
      const data = await response.json();
      setdataGaleria(data);
    //   setidData(data[0].id);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    
    fetchQuienes();
  }, []);

  const openDrawer = (perfil) => {
    setPerfilEdit(perfil);
    setDrawerVisible(true);
  };

  const openAddDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const saveChanges = async (perfil) => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("pdf_file", file);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/terminos/update/${perfil.id}/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchQuienes();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    closeDrawer();
    fetchQuienes();
  };

  const handleDelete = (perfil) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar el archivo " + perfil.pdf_file + "?");
    if (confirmDelete) {
      fetch(apiUrl + `/api/terminos/delete/${perfil.id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchQuienes();
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
    fetchQuienes();
  };

  const handleNewPerfilChange = (e) => {
    const { name, value } = e.target;
    setNewPerfil({ ...newPerfil, [name]: value });
  };

  const handleNewPerfilImageChange = (file) => {
    setNewPerfil({ ...newPerfil, imagen: file });
  };

  const handleSaveNewPerfil = async (e) => {
    e.preventDefault();
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("pdf_file", file);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/terminos/create`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    // closeDrawer();
    fetchQuienes();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      <SoftBox mb={3}>
        <Card>
          <SoftBox p={2}>
            <br></br>
            {"\n"}&nbsp;
            <Grid container spacing={3} justifyContent={isDesktop ? "center" : "left"}>
                {dataGaleria.length > 0 ? (
                  <>
                    {dataGaleria.map((perfil) => (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={3} key={perfil.id}>
                            <Card>
                              <SoftBox p={2}>
                                <a key={perfil.id} href={`${apiUrl}${perfil.pdf_file}`} target="blank">{`${apiUrl}${perfil.pdf_file}`}</a>
                              <IconButton
                                  onClick={() => openDrawer(perfil)}
                                  style={{
                                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                                  width: "40px", // Ajusta el ancho según tus necesidades
                                  height: "40px" // Ajusta la altura según tus necesidades
                                  }}
                              >
                                  <EditIcon />
                              </IconButton>
                              <IconButton
                                  onClick={() => handleDelete(perfil)}
                                  style={{
                                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                                  width: "40px", // Ajusta el ancho según tus necesidades
                                  height: "40px" // Ajusta la altura según tus necesidades
                                  }}
                              >
                                  <Delete />
                              </IconButton>
                              </SoftBox>
                              </Card>
                              </Grid>
                            {dataGaleria.indexOf(perfil) < dataGaleria.length - 1 && (
                                <Divider orientation="vertical" sx={{ mx: 0 }} />
                            )}
                        </>
                    ))}
                  </>
                ):(
                  <>
                    <form>
                      <SoftInput type="file" accept=".pdf" onChange={handleFileChange} />
                      <SoftButton type="submit" onClick={handleSaveNewPerfil}>Subir</SoftButton>
                    </form>
                  </>
                )}
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Drawer
        title="Editar Terminos"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        {perfilEdit && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <SoftInput type="file" accept=".pdf" onChange={handleFileChange} />
            <Button type="primary" onClick={() => saveChanges(perfilEdit)}>
              Guardar
            </Button>
          </Space>
        )}
        {!perfilEdit && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Upload
              name="imagen_banner"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => handleNewPerfilImageChange(file)}
            >
              {newPerfil.imagen_banner ? (
                <img src={URL.createObjectURL(newPerfil.imagen_banner)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <div>Subir Imagen</div>
              )}
            </Upload>
            <Input
              placeholder="Nombre Logo"
              value={newPerfil.pdf_file}
              onChange={handleNewPerfilChange}
              name="pdf_file"
            />
            <Button type="primary" onClick={handleSaveNewPerfil}>
              Guardar
            </Button>
          </Space>
        )}
      </Drawer>
    </>
  );
}

export default FileUploadComponent;
