import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React examples
import DashboardTable from "layouts/arbol/data/DashboardTable";
import Table from "examples/Tables/Table";
import { FaFileSignature } from "react-icons/fa";
// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import SoftButton from "components/SoftButton"
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useNavigate, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import {  Add } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Drawer, Upload, Space, Select, DatePicker } from "antd";
import SoftInput from "components/SoftInput";
import axios from "axios";
import Mapa from "layouts/arbol/data/mapa";
import { UserContext } from "App";
import SoftAvatar from "components/SoftAvatar";

const authorsTableData = {
  columns: [
    { name: "ID", align: "center" },
    { name: "fechaInicio", label: "Fecha de Inicio", align: "center" },
    { name: "fechaFin", label: "Fecha de Fin", align: "left" },
    { name: "Valor", label: "Valor Total", align: "left" },
    { name: "Arboles", label: "Valor Total", align: "left" },
    { name: "fechaPago", label: "Fecha de Pago", align: "center" },
    { name: "opciones", label: "Opciones", align: "center" },
  ],
};

function TableComprobantes() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);

  const [userEmail, setUserEmail] = useState(false);
  const [drawerVisibleAdd, setDrawerVisibleAdd] = useState(false);
  const [drawerDetalles, setDrawerDetalles] = useState(false);
  const [infoComprobante, setinfoComprobante] = useState(false);
  const [drawerVisibleEdit, setDrawerVisibleEdit] = useState(false);
  const [size, setSize] = useState();

  // MTM
  const [operadores, setOperadores] = useState([]);
  const [propietarios, setPropietarios] = useState([]);
  const [TiposArboles, setTiposArboles] = useState([]);
  const [potreros, setPotreros] = useState([]);
  const [fincas, setFincas] = useState([]);

  //Add

  const [newImagen, setNewImagen] = useState("");

  const [newValor, setNewValor] = useState();
  const [newFechaInicio, setNewFechaInicio] = useState(new Date());
  const [newFechaFin, setNewFechaFin] = useState(new Date());

  //edit

  const [latitud, setLatitud] = useState();
  const [longitud, setLongitud] = useState();

  const [editNickName, setEditNickname] = useState();
  const [editImagen, setEditImagen] = useState("");
  const [editOperador, setEditOperador] = useState();
  const [editLatitud, setEditLatitud] = useState();
  const [editLongitud, setEditLongitud] = useState();
  const [editPotrero, setEditPotrero] = useState([]);
  const [editFinca, setEditFinca] = useState([]);
  const [editTipo, setEditTipo] = useState([]);
  const [editFechaSiembra, setEditFechaSiembra] = useState(new Date());
  const [editPropietario, setEditPropietario] = useState();
  const [arbolId, setArbolId] = useState();
  const [editandoFecha, setEditandoFecha] = useState(false);

  const [selectedFile, setSelectedFile] = useState("");

  // new seguimiento
  const [newarbol, setnewarbol] = useState([]);

  const [initialData, setInitialData] = useState([]); // Datos originales

  const { RangePicker } = DatePicker;
 

  const handleEdit = (data) => {
    console.log('data', data);
    setSize("large");
    setEditingArbol(data);
    setDrawerVisibleEdit(true);
    setEditNickname(data.nickname);
    setEditImagen(data.imagen_principal);
    setEditFinca(data.finca.id);
    if (data.operador.id) {
      setEditOperador(data.operador.id);
    } else {
      setEditOperador(1);
    }
    
    setEditLatitud(data.longitud);
    setEditLongitud(data.latitud);
    setEditPotrero(data.potrero.id);
    setEditTipo(data.tipo_arbol.id);
    const editDate = new Date(data.fecha_siembra).toISOString().split('T')[0];
    setEditFechaSiembra(editDate);
    if (data.propietario.id) {
      setEditPropietario(data.propietario.id);
    } else {
      setEditPropietario(1);
    }
    
    setArbolId(data.id)
    setLatitud(data.latitud);
    setLongitud(data.longitud);    
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleAdminClick = () => {
    // Redireccionar al usuario a la URL del administrador
    window.location.href = "/firma";
  };

  const fetchTableProfile = async (user_id) => {
    try {
        const response = await fetch(`${apiUrl}/api/comprobantes/?user=${user_id}&format=json`);
        
        const data = await response.json();
        const formattedData = data.map(item => {
        const fecha_inicio = new Date(item.fecha_inicio).toISOString().split('T')[0];
        const fecha_fin = new Date(item.fecha_fin).toISOString().split('T')[0];
        // const imagenData = <SoftAvatar src={`${apiUrl}${item.user.avatar}`} alt="imagen" size="sm" variant="rounded" />;
        const opcionesData = (
          <>
            <IconButton
              onClick={() => setDrawerDetalles(item)}
              aria-label="Editars"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <VisibilityIcon />
            </IconButton>
            {item.id && (
              <IconButton
                onClick={handleAdminClick}
                aria-label="Editars"
                style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
              >
                <FaFileSignature />
              </IconButton>
            )}
          </>
        );

        const valorFormateado = parseFloat(item.precio).toLocaleString('es-ES', {
          minimumFractionDigits: 0,  // Sin decimales
          maximumFractionDigits: 0,  // Sin decimales
        });
        console.log('valorFormateado', valorFormateado);
        return {
            ID: item.id,
        //   Consecutivo: item.consecutico,
        //   Nickname: item.nickname,
          fechaInicio: fecha_inicio, // Aquí asignamos la fecha formateada
          Valor: valorFormateado,
          fechaFin: fecha_fin,
          fechaPago: new Date(item.created_at).toISOString().split('T')[0],
          Arboles: item.numero_arboles,
          // Usuario: imagenData,
          opciones: opcionesData,
        };
      });
      setCounterData(formattedData);
      setInitialData(formattedData);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };


  useEffect(() => {
    fetchTableProfile(user_id);
  }, []);


  const handleSaveComprobante = async () => {
     
    try {

      if (
        !newImagen ||
        !newValor

      ) {
        // Si alguno de los campos obligatorios está vacío, muestra un mensaje de error
        alert('Por favor, completa todos los campos obligatorios.');
        return;
      }

      if (newValor < 50000) {
        alert('El valor que se ingrese debe ser mayor a $50.000.');
        return;
      }

      // Validar que el valor sea múltiplo de 50,000
      if (newValor % 50000 !== 0) {
        alert('El valor por árbol es de $50.000. Ingresa un precio que sea múltiplo de $50.000.(100.000, 150.000, 200.000, ...)');
        return;
      }

      console.log('parseInt(user_id)', parseInt(user_id));
      const csrftoken = getCookie("csrftoken");
      console.log('csrftoken', csrftoken)
      const formDataAdd = new FormData();
      formDataAdd.append("precio", newValor);
      formDataAdd.append("user", parseInt(user_id));
      formDataAdd.append('imagen', newImagen);

      //formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/comprobante/create/`, {
        method: "POST",
        mode: 'no-cors',
        
        headers: {
          "X-CSRFToken": csrftoken,
          //'Content-Type':  'multipart/form-data',
        },
        body: formDataAdd,
        credentials: 'include',
      });
      
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    setDrawerVisibleAdd(false);
    fetchTableProfile(user_id);
    clearData();
  };
  

  const openAddDrawer = () => {
    setDrawerVisibleAdd(true);
    setSize("large");
  };


  const clearData = () => {
    setNewImagen("");
    setNewValor("");
    setNewFechaInicio("");
    setNewFechaFin("");
  };


  const openAddSeguimiento = (arbol_id) => {
    setnewarbol(arbol_id);
    setSize("large");
    setSeguimientoAdd(true);
  };


  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Lista de Comprobantes</SoftTypography>
              <IconButton
              style={{
                position: "abslolute",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
              onClick={openAddDrawer}
            >
              <SoftTypography variant="h6">Agregar</SoftTypography> <Add />
            </IconButton>
            </SoftBox>
            {/* <SoftBox
              style={{ display: 'flex',
                      justifyContent: 'center'
                    }}
            >
              {renderFilters()}
            </SoftBox> */}
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={counterData} />
              
            </SoftBox>
          </Card>
        </SoftBox>
      </DashboardTable>
      <Drawer
        title="Subir Comprobante"
        anchor="right"
        size={size}
        open={drawerVisibleAdd}
        
        onClose={() => setDrawerVisibleAdd(false)}
        footer={
          <Space>
            <SoftButton  onClick={() => setDrawerVisibleAdd(false)}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveComprobante}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <SoftTypography variant="body2" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Cargar Imagen&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Upload
                name="imagen"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => setNewImagen(file)}
                accept="image/*"
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setNewImagen(info.file.originFileObj);
                  }
                }}
              >
                {newImagen ? (
                  <img src={URL.createObjectURL(newImagen)} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  <img src={rocketWhite} alt="avatar" style={{ width: "100%" }} />
                )}
              </Upload>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Valor&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                step="any"
                name="precio"
                value={newValor}
                min={50000} 
                onChange={(event) => setNewValor(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              {/* <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Fecha de Inicio&nbsp;&nbsp;</strong>
              </SoftTypography>
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Inicio"
                    value={newFechaInicio}
                    onChange={(event) => setNewFechaInicio(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong><span style={{ color: 'red' }}>*&nbsp;</span>Fecha de Fin&nbsp;&nbsp;</strong>
              </SoftTypography>
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Fin"
                    value={newFechaFin}
                    onChange={(event) => setNewFechaFin(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </>
              <br></br>
              {"\n"}&nbsp;
              {"\n"} */}
              
            </SoftBox>
          </form>
      </Drawer>

      {/* ver datos  */}

      <Drawer
        title="Detalles del Comprobante"
        anchor="right"
        size='large'
        open={drawerDetalles}
        
        onClose={() => setDrawerDetalles(false)}
        footer={
          <Space>
            <SoftButton  onClick={() => setDrawerDetalles(false)}>Cerrar</SoftButton>
          </Space>
        }
      >

        <Card key={drawerDetalles.id}>
          <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
            Comprobante No. {drawerDetalles.id}
          </SoftTypography>
          <SoftBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <SoftBox display="flex" flexDirection="column" height="100%">
                  <SoftBox mb={6}>
                    <SoftTypography variant="h6" color="text">
                      Periodo: Desde  {new Date(drawerDetalles.created_at).toLocaleString('es-ES', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                      hour12: true
                                    })} hasta {new Date(drawerDetalles.fecha_fin).toLocaleString('es-ES', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                      hour12: true
                                    })}.
                    </SoftTypography>
                    <br></br>
                    <br></br>
                    <SoftTypography variant="body2" color="text">
                      Fecha de carga:  {new Date(drawerDetalles.created_at).toLocaleString()}
                    </SoftTypography>

                    <SoftTypography variant="body2" color="text">
                      Por valor de: ${parseFloat(drawerDetalles.precio).toLocaleString('es-ES', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </SoftTypography>
                    <SoftTypography variant="body2" color="text">
                        Numero de árboles: {drawerDetalles.numero_arboles}
                    </SoftTypography>
                  
                    <SoftTypography variant="body2" color="text">
                        Comprobante cargado:
                    </SoftTypography>
                  <br></br>
                  </SoftBox>
                  <SoftBox component="img" src={`${apiUrl}${drawerDetalles.imagen}`} alt="{drawerDetalles.imagen}" width="100%"  />
                </SoftBox>
              </Grid>
              {/* <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
                <SoftBox
                  height="100%"
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  bgColor="red"
                  borderRadius="lg"
                  variant="gradient"
                >
                  <SoftBox component="img" src={`${apiUrl}${drawerDetalles.imagen}`} alt="rocket" width="100%" pt={3} />
                </SoftBox>
              </Grid> */}
            </Grid>
          </SoftBox>
        </Card>
      </Drawer>




    </>
  );
}

export default TableComprobantes;
