/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";

import TableSeguimiento from "layouts/seguimientos/data/TableSeguimiento";
import { Link, useAsyncError, useParams } from "react-router-dom";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import { Card } from "@material-ui/core";
import io from "socket.io-client";
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function WhatsAppComponent() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const newSocket = io("localhost:3001");
    setSocket(newSocket);

    newSocket.on("message", (message) => {
      setMessages([...messages, message]);
    });

    return () => newSocket.disconnect();
  }, []);

  const sendMessage = (message) => {
    socket.emit("message", message);
  };
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent={isMobile ? "left" : "center"} alignItems="center" sx={{ mt: 3 }}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Card>
            <iframe
              src="https://chat.somosverdeser.com/"
              title="Embedded Page"
              width="100%"
              height={isMobile ? "400px" : "600px"}
              frameBorder="0"
            />
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default WhatsAppComponent;
