/* eslint-disable react/prop-types */
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const authorsTableData = {
  columns: [
    { name: "Título", align: "left" },
    { name: "encargado", align: "left" },
    { name: "descripcion", align: "left" },
    { name: "estado", align: "center" },
    { name: "Revisión", align: "center" },
    { name: "Acción", align: "center" },
  ],

  rows: [
    {
      Título: <Function job="Arból #1" org="Arból #1" />,
      encargado: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      descripcion: <Function job="Pequeña entradilla del estado del arbol"/>,
      estado: (
        <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
      ),
      Revisión: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          23/04/18
        </SoftTypography>
      ),
      Acción: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Título: <Function job="Arból #2" org="Arból #2" />,
      encargado: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      descripcion: <Function job="Pequeña entradilla del estado del arbol"/>,
      estado: (
        <SoftBadge variant="gradient" badgeContent="offline" color="secondary" size="xs" container />
      ),
      Revisión: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          11/01/19
        </SoftTypography>
      ),
      Acción: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Título: <Function job="Arból #3" org="Arból #3" />,
      encargado: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      descripcion: <Function job="Pequeña entradilla del estado del arbol"/>,
      estado: (
        <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
      ),
      Revisión: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          19/09/17
        </SoftTypography>
      ),
      Acción: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Título: <Function job="Arból #4" org="Arból #4" />,
      encargado: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      descripcion: <Function job="Pequeña entradilla del estado del arbol"/>,
      estado: (
        <SoftBadge variant="gradient" badgeContent="online" color="success" size="xs" container />
      ),
      Revisión: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          24/12/08
        </SoftTypography>
      ),
      Acción: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Título: <Function job="Arból #5" org="Arból #5" />,
      encargado: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      descripcion: <Function job="Pequeña entradilla del estado del arbol"/>,
      estado: (
        <SoftBadge variant="gradient" badgeContent="offline" color="secondary" size="xs" container />
      ),
      Revisión: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          04/10/21
        </SoftTypography>
      ),
      Acción: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Título: <Function job="Arból #6" org="Arból #6" />,
      encargado: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
      descripcion: <Function job="Pequeña entradilla del estado del arbol"/>,
      estado: (
        <SoftBadge variant="gradient" badgeContent="offline" color="secondary" size="xs" container />
      ),
      Revisión: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          14/09/20
        </SoftTypography>
      ),
      Acción: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
  ],
};

export default authorsTableData;
