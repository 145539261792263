import React, { useRef, useState, useContext, useEffect } from "react";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Signature from '@uiw/react-signature';
// react-pdf
import { Document, Page } from 'react-pdf';

// Importar pdfjs-dist para configurar el worker
import { pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import apiUrl from "domain";
import { UserContext } from "App";
import TableDocumentos from "layouts/firma/TablaDocumentos";

// Configuración del worker usando un CDN (no necesitas pdfjs-dist/build/pdf.worker.entry)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function FirmaDigital() {
  const $svg = useRef(null);
  const sigCanvas = useRef(null);  // Usamos la referencia al componente SignatureCanvas

  const [firmaExistente, setFirmaExistente] = useState(null); // Estado para guardar la firma base64
  const [isFirmaNueva, setIsFirmaNueva] = useState(false);
  const user_id = useContext(UserContext);
  const [firmaID, setfirmaID] = useState(null);


  const [imageSrc, setImageSrc] = useState(null); // Estado para almacenar la firma cargada como imagen
  const handleClear = () => {
    setImageSrc(null);  // Limpiar la imagen cargada
    //$svg.current?.clear();  // Limpiar el área de firma si es necesario
    sigCanvas.current.clear();
  };

  

  const cargarFirma = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/firma/${user_id}/`);  // La URL de tu endpoint
      const data = await response.json();
      if (data.firma_digital) {
        setFirmaExistente(data.firma_digital.replace(/^data:image\/png;base64,/, ''));  // Si existe firma, la seteamos
        setfirmaID(data.id);
      } else {
        setIsFirmaNueva(true);  // Si no existe firma, habilitamos la creación de nueva
      }
    } catch (error) {
      console.error("Error al obtener la firma:", error);
    }
  };

  const clearCanvas = () => {
    // sigCanvas.current.clear();
    setFirmaExistente(false);
  };
  const cancelarFirma = () => {
    // sigCanvas.current.clear();
    setFirmaExistente(true);
    cargarFirma();
  };

  useEffect(() => {
    cargarFirma();
  }, []);

  // Función para manejar la carga de la firma (imagen)
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);  // Establecer la imagen cargada
      };
      reader.readAsDataURL(file);
    }
  };

  // Puedes cargar un PDF estático o dinámico
  const pdfUrl = "https://backend.somosverdeser.com//media/landing/terms_conditions/27022024_Tratamiento_de_datos_personales_Verdeser_SStWUIY.pdf"; // Cambia esto con la URL de tu PDF

  const handleSaveSignature = async () => {
    let firmaDigital = '';

    // Si hay firma generada
    if (sigCanvas.current) {
      firmaDigital = sigCanvas.current.getTrimmedCanvas().toDataURL();  // Obtener la firma como base64
    }

    // Si hay firma cargada
    else if (imageSrc) {
      firmaDigital = imageSrc;  // La firma cargada como base64
    }

    if (firmaID) {
      console.log('actualizando firma', firmaID);
      formData.append('id_firma', firmaID);
    }

    // Enviar la firma al backend
    const formData = new FormData();
    formData.append('firma_digital', firmaDigital);
    formData.append('imagen', imageSrc);
    formData.append("user", parseInt(user_id));
    

    try {
      const response = await axios.post(`${apiUrl}/api/guardar-firma/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Firma guardada', response.data);
      cargarFirma();
    } catch (error) {
      console.error('Error guardando la firma', error);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card xl={12}>
        <Grid container spacing={3} justifyContent="center">
        {!firmaExistente ? (
          <>
          <SoftBox py={3}>
            <p>Aquí puedes cargar o realizar tu firma digital que quedará en los acuerdos de reforestación participativa</p>

            
            {/* Mostrar la firma cargada como imagen si existe */}
            {imageSrc ? (
              <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <img
                  src={imageSrc}
                  alt="Firma Cargada"
                  style={{ width: '100%', maxWidth: '250px', height: 'auto', border: '2px solid #ccc' }}
                />
              </div>
            ) : (
              /* Si no hay imagen cargada, mostrar el cuadro de firma */
              <div style={{
                border: '2px solid #ccc', // Agrega borde alrededor del área de firma
                width: '100%', // Ajusta el tamaño (ancho) al 100% del contenedor
                maxWidth: '250px', // Limita el tamaño máximo
                height: '120px', // Ajusta el tamaño (alto)
                marginBottom: '20px', // Añade espacio entre la firma y el botón
                margin: '0 auto', // Centra el área de firma
              }}>
                <div>
                  {imageSrc ? (
                    <img src={imageSrc} alt="Firma cargada" />
                  ) : (
                    <div ref={$svg}> {/* Aquí irá la firma */}
                      <SignatureCanvas
                        ref={sigCanvas}
                        backgroundColor="white"
                        penColor="black"
                        canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }}
                      />
                    </div>
                  )}
                </div>
                
              </div>
            )}

            {/* Contenedor para los botones centrados */}
            <div style={{
              display: 'flex',
              justifyContent: 'center',  // Centrar horizontalmente
              alignItems: 'center',      // Centrar verticalmente
              flexDirection: 'column',   // Los botones estarán apilados verticalmente
              width: '100%',
            }}>
              {/* Botón para limpiar la firma */}
              <SoftBox>
                <button 
                  onClick={handleClear} 
                  style={{
                    padding: '8px 15px', 
                    fontSize: '14px', 
                    backgroundColor: '#007bff', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '5px', 
                    cursor: 'pointer', 
                    width: '100%', // Hace que el botón ocupe todo el ancho disponible
                    maxWidth: '150px', // Limita el tamaño máximo del botón
                    margin: '10px auto', // Centra el botón
                  }}
                >
                  Limpiar
                </button>
              </SoftBox>

              <SoftBox>
                <button 
                  onClick={cancelarFirma} 
                  style={{
                    padding: '8px 15px', 
                    fontSize: '14px', 
                    backgroundColor: '#007bff', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '5px', 
                    cursor: 'pointer', 
                    width: '100%', // Hace que el botón ocupe todo el ancho disponible
                    maxWidth: '150px', // Limita el tamaño máximo del botón
                    margin: '10px auto', // Centra el botón
                  }}
                >
                  Cancelar
                </button>
              </SoftBox>

              {/* Botón para cargar una imagen de la firma */}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  margin: '10px 0',
                  padding: '5px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  display: 'block',
                  width: '100%',
                  maxWidth: '250px', // Limita el tamaño máximo del input
                  margin: '10px auto',
                }}
              />
              <SoftBox>
                <button 
                  onClick={handleSaveSignature} 
                  style={{
                    padding: '8px 15px', 
                    fontSize: '14px', 
                    backgroundColor: '#007bff', 
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '5px', 
                    cursor: 'pointer',
                    width: '100%', // Hace que el botón ocupe todo el ancho disponible
                    maxWidth: '150px', // Limita el tamaño máximo del botón
                    margin: '10px auto', // Centra el botón
                  }}
                >
                  Firma
                </button>
              </SoftBox>
            </div>
          </SoftBox>
          </>
      ) : (
        
          <div>
          <h3>Firma Cargada:</h3>
          <img src={firmaExistente} alt="Firma existente" style={{ width: '500px', height: '200px' }} />
          <button
            onClick={clearCanvas}
            style={{
              padding: '8px 15px', 
              fontSize: '14px', 
              backgroundColor: '#007bff', 
              color: 'white', 
              border: 'none', 
              borderRadius: '5px', 
              cursor: 'pointer', 
              width: '100%', // Hace que el botón ocupe todo el ancho disponible
              maxWidth: '150px', // Limita el tamaño máximo del botón
              margin: '10px auto', // Centra el botón
            }}
          >
            Editar Firma
          </button>
        </div>
      )}  
      {/* Contenedor del PDF con ajustes responsivos y barras de desplazamiento */}
            {/* <div style={{
                width: '100%', 
                height: '500px', // Ajusta la altura para que se vea bien en dispositivos móviles y escritorio
                overflow: 'auto', // Permite las barras de desplazamiento si el contenido es más grande
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',  // Centra el PDF en pantallas grandes
              }}>
              <Document
                file={pdfUrl}
                loading={<span>Cargando...</span>}
                error={<span>No se pudo cargar el PDF.</span>}
              >
                <Page pageNumber={1} />
              </Document>
            </div> */}

        </Grid>
      </Card>

      <Card xl={12}>
        <TableDocumentos />
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default FirmaDigital;
