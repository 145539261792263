import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import apiUrl from "domain";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import About from "layouts/quienessomos/about";
import Equipo from "layouts/quienessomos/equipo";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Imagenes from "layouts/galeria/imagenes";
import { UserContext } from "App";
import Logo from "./logo";


function Logos() {
  const user_id = useContext(UserContext); 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Logo />
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Logos;
