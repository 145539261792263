import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from 'antd';
import { Document, Page } from 'react-pdf';
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Table from "examples/Tables/Table";
import apiUrl from "domain";
import { UserContext } from "App";
import axios from 'axios';

const authorsTableData = {
  columns: [
    { name: "ID", align: "center" },
    { name: "Documento", align: "center" },
    { name: "FechaGeneracion", align: "center" },
    { name: "opciones", align: "center" },
  ],
};

function TableDocumentos() {
  const { columns } = authorsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const showModal = (data) => {
    setIsModalOpen(true);
    setmodalData(data);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Función para obtener el número de páginas del PDF
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const fetchTableProfile = async () => {
    try {
      const response = await fetch(apiUrl + "/api/documentos/" + user_id + "?format=json");
      const data = await response.json();
      const formattedData = data.map(item => {
        // Capitalizar el tipo de documento
        let contrato = item.tipo_documento.charAt(0).toUpperCase() + item.tipo_documento.slice(1);
        const registrodDate = new Date(item.fecha_generacion).toISOString().split('T')[0];
        const opcionesData = <SoftTypography type="primary" onClick={() => showModal([item])}>...</SoftTypography>;

        return {
          ID: item.id,
          Documento: contrato,
          FechaGeneracion: registrodDate,
          opciones: opcionesData,
        };
      });
      setCounterData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Llamar a la función fetchTableProfile cuando el componente se monte
  useEffect(() => {
    fetchTableProfile();
  }, [user_id]);



  const firmarDocumento = async (id_documento) => {

    // Enviar la firma al backend
    const formData = new FormData();
    console.log('id_documento', id_documento);
    console.log('user_id', user_id);
    formData.append('user', user_id);
    formData.append('id_documento', id_documento);
    try {
      const response = await axios.post(`${apiUrl}/api/firmar_documentos/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Firma guardada', response.data);
      handleOk();
      fetchTableProfile();
    } catch (error) {
      console.error('Error guardando la firma', error);
    }
  };

  const downloadFile = (fileUrl) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileUrl.split('/').pop(); // El nombre del archivo es el último fragmento de la URL
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error('Error al descargar el archivo:', error));
  };


  

  return (
    <>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Mis Documentos</SoftTypography>
        </SoftBox>
        <SoftBox>
          <Table columns={columns} rows={counterData} />
        </SoftBox>
      </Card>

      {/* Modal con el PDF */}
      {modalData && (
        <Modal
          title="Detalles del Documento"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div>
            {modalData.map((data) => (
              <Card key={data.id}>
                <div style={{
                  width: '100%',
                  height: '500px',
                  overflow: 'auto',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <Document
                    file={data.documento ? apiUrl + data.documento.replace('media/', '') : null}
                    onLoadSuccess={onLoadSuccess}
                    loading={<span>Cargando...</span>}
                    error={<span>No se pudo cargar el PDF.</span>}
                  >
                    {/* Renderizar todas las páginas */}
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={index} pageNumber={index + 1} />
                    ))}
                  </Document>
                </div>
                
                {/* Mostrar botón de firma solo si no tiene firma */}
                {!data.firma ? (
                  <button
                    onClick={() => firmarDocumento(data.id)}
                    style={{
                      padding: '8px 15px', 
                      fontSize: '14px', 
                      backgroundColor: '#007bff', 
                      color: 'white', 
                      border: 'none', 
                      borderRadius: '5px', 
                      cursor: 'pointer', 
                      width: '100%', // Hace que el botón ocupe todo el ancho disponible
                      maxWidth: '150px', // Limita el tamaño máximo del botón
                      margin: '10px auto', // Centra el botón
                    }}
                  >
                    Firma
                  </button>
                ) : 
                  (
                   
                    <a
                      onClick={() => downloadFile(data.documento ? apiUrl + data.documento.replace('media/', '') : '')}
                      style={{
                        display: 'block',
                        padding: '8px 15px', 
                        fontSize: '14px', 
                        backgroundColor: '#28a745', 
                        color: 'white', 
                        border: 'none', 
                        borderRadius: '5px', 
                        cursor: 'pointer', 
                        textAlign: 'center',
                        width: '100%',
                        maxWidth: '150px', 
                        margin: '10px auto',
                        textDecoration: 'none',
                      }}
                    >
                      Descargar PDF
                    </a>
                  )
                }
              </Card>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
}

export default TableDocumentos;
