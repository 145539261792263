import React, { useEffect, useState, useContext } from "react";
import { Card, IconButton, TextField, Modal } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import Table from "examples/Tables/Table";
import apiUrl from "domain";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import { Link, useAsyncError, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Drawer, Input, Upload, Space, Select  } from "antd";
import SoftInput from "components/SoftInput";
import Icon from "@mui/material/Icon";
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Delete, Add, Save, CollectionsOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import axios from "axios";
import Mapa from "layouts/arbol/data/mapa";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import Grid from "@mui/material/Grid";
// import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import typography from "assets/theme/base/typography";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import { UserContext } from "App";
import team2 from "assets/images/team-2.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";

const authorsTableData = {
  columns: [
    { name: "Id", align: "center" },
    { name: "Operador", align: "center" },
    { name: "Fecharegistro", align: "center" },
    { name: "tipoarbol", align: "center" },
    { name: "fechasiembra", align: "center" },
    { name: "Vista", align: "center" },
    { name: "opciones", align: "center" },
  ],
};

function TableSeguimiento() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const { columns } = authorsTableData;
  const [counterData, setCounterData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const user_id = useContext(UserContext);
  const [sized, setSize] = useState();
  const [operador, setoperador] = useState("")
  const [operadores, setoperadores] = useState([]);
  const [fecha_registro, setfecha_registro] = useState([]);
  const [fechaRegistro, setFechaRegistro] = useState(new Date());
  const [estado_arbol, setestado_arbol] = useState(null);
  const [altura, setaltura] = useState(null);
  const [atencion_prioritaria, setatencion_prioritaria] = useState([]);
  const [atencion_prioritarias, setatencion_prioritarias] = useState([]);
  const [condicion, setcondicion] = useState([]);
  const [condiciones, setcondiciones] = useState([]);
  const [imagenes, setimagenes] = useState([]);
  const [arbol, setarbol] = useState([]);
  const [arboles, setarboles] = useState([]);
  const [editandoFecha, setEditandoFecha] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewObservacion, setPreviewObservacion] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingFields, setEditingFields] = useState(false);
  const [editedImage, setEditedImage] = useState(null);
  const [editedObservacion, setEditedObservacion] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagenFile, setImagen] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [ImagenId, setImagenId] = useState(false);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [seguimientoId, setSeguimientoId] = useState();
  const [newImage, setNewImage] = useState({
    observacion: "",
    imagen: null,
  });
  const [newObservacion, setNewObservacion] = useState("");
  const [drawerVisibleAdd, setDrawerVisibleAdd] = useState(false);
  const [newSeguimiento, setNewSeguimiento] = useState({
    operador: "",
    fecha_registro: "",
    estado_arbol: "",
    altura: null,
    atencion_prioritaria: null,
    condicion: null,
    arbol: null,
  });

  const [newoperador, setnewoperador] = useState("")
  const [newfecha_registro, setnewfecha_registro] = useState([]);
  const [newestado_arbol, setnewestado_arbol] = useState(null);
  const [newaltura, setnewaltura] = useState(null);
  const [newatencion_prioritaria, setnewatencion_prioritaria] = useState([]);
  const [newcondicion, setnewcondicion] = useState([]);
  const [newarbol, setnewarbol] = useState([]);
  const { arbol_id, latitud, longitud } = useParams();
  const [nombreArbol, setNombreArbol] = useState("")
  const [gradientLineChartData, setgradientLineChartData] = useState([]);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchGraficaSeguimiento = async () => {
    try {
      const response = await fetch(apiUrl + "/api/arbol/crecimiento/"+ arbol_id + "/?format=json");
      const data = await response.json();
      // setgradientLineChartData(data);
      setgradientLineChartData({
        labels: data['labels'],
        datasets: [
          {
            label: "Crecimiento",
            color: "info",
            data: data['datasets'],
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTableSeguimiento = async () => {
    try {
      const response = await fetch(apiUrl + "/api/seguimientos/arbol/"+ arbol_id + "/?format=json");
      const data = await response.json();
      const formattedData = data.map(item => {
        setNombreArbol(item.arbol.nickname);
        console.log('itemitemitem', item);
        const dataIdPk = item.id;
        const registrodDate = new Date(item.fecha_registro).toISOString().split('T')[0];
        const siembraodDate = new Date(item.arbol.fecha_siembra).toISOString().split('T')[0];
        const nombreData = <SoftTypography type="primary" onClick={() => showModal([item])}>{item.arbol.nickname}</SoftTypography>;
        const imagenData = <SoftAvatar src={ item.operador.avatar ? `${apiUrl}${item.operador.avatar}` : `${apiUrl}/media/landing/equipo/persona2.jpg` } alt={item.arbol.nickname} size="sm" variant="rounded" />;
        const opcionesData = (
          <>
            <IconButton
              onClick={() => handleEdit(item)}
              aria-label="Editars"
              style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteSeguimiento(item)}
              aria-label="Eliminar"
              style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
        const VistaPrevia = (
          <>
            <Link to={`/arbol/seguimiento/${item.id}`} target="_blank">
              <SoftTypography variant="button" fontWeight="regular" color="text">
                <strong>Ver</strong>
              </SoftTypography>
            </Link>
          </>
        );
        return {
          Id: dataIdPk,
          Nickname: nombreData,
          Fecharegistro: registrodDate,
          tipoarbol: item.arbol.tipo_arbol.nombre,
          fechasiembra: siembraodDate,
          Operador: imagenData,
          Vista: VistaPrevia,
          opciones: opcionesData,
        };
      });
      setCounterData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOperadores = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/operadores/`);
      const data = await response.json();
      setoperadores(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchPrioridad = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/prioridad/`);
      const data = await response.json();
      setatencion_prioritarias(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchCondicion = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/condicion/`);
      const data = await response.json();
      setcondiciones(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchArbol = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/arboles-all/`);
      const data = await response.json();
      setarboles(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  useEffect(() => {
    fetchTableSeguimiento();
    fetchGraficaSeguimiento();
    fetchOperadores();
    fetchPrioridad();
    fetchCondicion();
    fetchArbol();
  }, []);

  const handleEdit = (data) => {
    setSeguimientoId(data.id);
    // setSize("large");
    setDrawerVisible(true);
    setEditData(data);
    setoperador(data.operador.user.id);
    const editDate = new Date(data.fecha_registro);
    setfecha_registro(editDate);
    setatencion_prioritaria(data.atencion_prioritaria.id);
    setestado_arbol(data.estado_arbol);
    setaltura(data.altura);
    setcondicion(data.condicion.id);
    setimagenes(data.imagenes);
    setarbol(data.arbol.id);
  };

  const handleAddNewImagen = (data) => {
    setModalOpenAdd(true);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(apiUrl + `/api/seguimiento/update/${editData.id}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          operador: operador, // Asumiendo que operador es el ID del UserProfile seleccionado
          //fecha_registro: new Date(fecha_registro).toISOString().split('T')[0], // Convertir la fecha a ISO string
          estado_arbol: estado_arbol,
          altura: altura,
          atencion_prioritaria: atencion_prioritaria, // Asumiendo que atencion_prioritaria es el ID de la Prioridad seleccionada
          condicion: condicion, // Asumiendo que condicion es el ID de la Condicion seleccionada
          arbol: arbol, // Asumiendo que arbol
          imagenes: imagenes.map(image => image.id) // Asumiendo que imagenes es un array de objetos Imagen con ID
        })
      });
      if (response.ok) {
        console.log('Data updated successfully');
      } else {
        console.error('Failed to update data');
      }
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setDrawerVisible(false);
      fetchObtenerImagenes(seguimientoId);
      fetchTableSeguimiento();
    }
  };

  const handleSaveImage = async () => {
    const csrftoken = getCookie("csrftoken");
    const formData = new FormData();
    formData.append("imagen", selectedFile);
    formData.append("observacion", editedObservacion);
    try {
      // console.log("estado_arbol", estado_arbol);
      const response = await axios.post(
        `${apiUrl}/api/seguimiento/update/${ImagenId}/imagen`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setEditingFields(false);
      setModalOpen(false);
      // setimagenes(data.imagenes);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleFechaEdit = () => {
    setEditandoFecha(true); // Activar la edición de la fecha
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedImages([...selectedImages, ...files]);
  };

  const handleAddImages = () => {
    // Simplemente abrir el selector de archivos nuevamente
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = true;
    input.click();
    input.addEventListener('change', handleImageChange);
  };

  const handlePreviewImage = (imageUrl, observacion, id) => {
    setPreviewImage(imageUrl);
    setPreviewObservacion(observacion);
    setEditedImage(imageUrl);
    setEditedObservacion(observacion);
    setImagenId(id);
    setModalOpen(true);
  };

  const closePreviewImage = () => {
    setPreviewImage(null);
    setModalOpen(false);
  };
  const handleEditModalFields = () => {
    // Cambia el estado para indicar que los campos están habilitados para la edición
    setEditingFields(true);
  };


  const setCerrarModal = () => {
    setModalOpen(false);
    setEditingFields(false);
  };
  const setCerrarModalAdd = () => {
    setModalOpenAdd(false);
    setNewObservacion("");
    setNewImage({
      observacion: "",
      imagen: null,
    })
  };

  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      setImagen(URL.createObjectURL(file));
      setIsImageChanged(true);
      setPreviewImage(URL.createObjectURL(file));
    };
    input.click();
  };

  const handleNewsetNewObservacionChange = (e) => {
    console.log('e', e);
    const { observacion, value } = e.target;
    setNewImage({ ...newImage, [observacion]: value });
  };

  const handleNewImageChange = (file) => {
    setNewImage({ ...newImage, imagen: file });
  };

  const fetchObtenerImagenes = async (seguimientoId) => {
    try {
      const response = await fetch(apiUrl + "/api/seguimientos-filter/" + seguimientoId + "/?format=json");
      const data = await response.json();
      const formattedData = data.map(item => {
        setimagenes(item.imagenes);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSaveNewImagen = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("imagen", newImage.imagen);
      formDataAdd.append("observacion", newImage.observacion);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      formDataAdd.append('seguimiento_id', seguimientoId)
      const response = await fetch(`${apiUrl}/api/seguimiento/imagen/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      // fetchBgImage();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    fetchObtenerImagenes(seguimientoId);
    setModalOpenAdd(false);
    setNewObservacion("");
    setNewImage({
      observacion: "",
      imagen: null,
    })
  };

  const handleDelete = (ImagenId) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar a la imagen? "+ImagenId);
    if (confirmDelete) {
      fetch(apiUrl + `/api/seguimiento/imagen/delete/${ImagenId}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchObtenerImagenes(seguimientoId);
          setModalOpen(false);
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const handleDeleteSeguimiento = (item) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar el seguimiento? " + item.id);
    if (confirmDelete) {
      fetch(apiUrl + `/api/seguimiento/delete/${item.id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchTableSeguimiento();
          fetchOperadores();
          fetchPrioridad();
          fetchCondicion();
          fetchArbol();
          setModalOpen(false);
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const openAddDrawer = () => {
    setDrawerVisibleAdd(true);
    // setSize("large");
  };

  const handleSaveNewSeguimiento = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("operador_id", newoperador);
      formDataAdd.append("fecha_registro", new Date(newfecha_registro).toISOString().split('T')[0],);
      formDataAdd.append("estado_arbol", newestado_arbol);
      formDataAdd.append("altura", newaltura);
      formDataAdd.append("atencion_prioritaria_id", newatencion_prioritaria);
      formDataAdd.append("condicion_id", newcondicion);
      formDataAdd.append("arbol_id", newarbol);
      //formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/seguimiento/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchBgImage();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    setDrawerVisibleAdd(false);
    fetchTableSeguimiento();
    fetchOperadores();
    fetchPrioridad();
    fetchCondicion();
    fetchArbol();
  };

  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Seguimientos de {nombreArbol}</SoftTypography>
            </SoftBox>
            {/* <IconButton
            style={{
              position: "abslolute",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={openAddDrawer}
            >
              <Add />
            </IconButton> */}
            <SoftBox>
              <Table columns={columns} rows={counterData} />
            </SoftBox>
          </Card>
          
        </SoftBox>
        <SoftBox mb={3}>
          <Card>
            <SoftBox justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Crecimiento de {nombreArbol}</SoftTypography>
            </SoftBox>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                height="calc(100% - 3.5rem)"
                chart={gradientLineChartData}
              />
            </Grid>
          </Card>
          
        </SoftBox>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Ubicación de {nombreArbol}</SoftTypography>
            </SoftBox>
            {/* <IconButton
            style={{
              position: "abslolute",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={openAddDrawer}
            >
              <Add />
            </IconButton> */}
            <SoftBox>
            {longitud && latitud &&(
              <>
            <h1>Mapa</h1>
            <Mapa latitud={latitud} longitud={longitud} />
            </>
            )}
            </SoftBox>
          </Card>
          
        </SoftBox>
      </DashboardTable>
      <Drawer
        title={"Editar Seguimiento de " + nombreArbol}
        anchor="right"
        size={sized}
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        footer={
          <Space>
            <SoftButton onClick={() => setDrawerVisible(false)}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSave}>Guardar</SoftButton>
          </Space>
        }
      >
        {editData && (
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Operador</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={operador}
                onChange={(values) => setoperador(values)}
              >
                {operadores.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Fecha de Registro&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                    <Icon color="primary" fontSize="small">
                      date_range 
                    </Icon>
                    &nbsp;&nbsp;&nbsp;&nbsp;{fecha_registro.toISOString().split('T')[0]}&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
              {/* {!editandoFecha ? (
                <>
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    <Icon color="primary" fontSize="small">
                      date_range 
                    </Icon>
                    &nbsp;&nbsp;&nbsp;&nbsp;{fecha_registro.toISOString().split('T')[0]}&nbsp;&nbsp;&nbsp;
                  </SoftTypography>
                  <SoftButton color="primary" onClick={handleFechaEdit}>Cambiar</SoftButton>
                </>
              ) : (
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Registro"
                    value={fecha_registro}
                    onChange={(event) => setfecha_registro(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <SoftButton color="info" onClick={() => setEditandoFecha(false)}>Cancelar</SoftButton>
                </>
              )} */}
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Altura en cms&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                name="altura"
                value={altura}
                onChange={(event) => setaltura(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Prioridad&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={atencion_prioritaria}
                onChange={(values) => setatencion_prioritaria(values)}
              >
                {atencion_prioritarias.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Condicion&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={condicion}
                onChange={(values) => setcondicion(values)}
              >
                {condiciones.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Arbol&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={arbol}
                onChange={(values) => setarbol(values)}
              >
                {arboles.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nickname}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Estado&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                // type="text"
                placeholder="Observaciones"
                name="estado_arbol"
                value={estado_arbol}
                onChange={(event) => setestado_arbol(event.target.value)} 
                fullWidth
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Imagenes</strong>
              </SoftTypography>
              <br></br>
              {"\n"}
              {"\n"}
              {imagenes.map((image) => (
                <img
                  key={image.id}
                  src={`${apiUrl}${image.imagen}`}
                  alt="imagen"
                  size="sm"
                  // variant="rounded"
                  onClick={() => handlePreviewImage(`${apiUrl}${image.imagen}`, image.observacion, image.id)}
                  style={{ maxWidth: "30%", maxHeight: "300%", borderRadius: "8px", margin: '5px' }}
                />
              ))}
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftBox>
                {/* Agregar campo de entrada de tipo archivo para subir imágenes */}
                {/* <input type="file" accept="image/*" multiple onChange={handleImageChange} /> */}
                <br />
                {/* Mostrar imágenes seleccionadas */}
                {selectedImages.map((image, index) => (
                  <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ maxWidth: '100px', maxHeight: '100px', margin: '5px' }} />
                ))}
                <IconButton
                  color="primary"
                  aria-label="Agregar"
                  onClick={handleAddNewImagen}
                  style={{
                    backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                    borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                    width: "40px", // Ajusta el ancho según tus necesidades
                    height: "40px" // Ajusta la altura según tus necesidades
                  }}
                  >
                    <Add />
                </IconButton>
              </SoftBox>
            </SoftBox>
          </form>
        )}
      </Drawer>
      <Dialog
        open={modalOpen}
        onClose={closePreviewImage}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent dividers>
          <SoftBox p={2}>
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: "50%", maxHeight: "50%", borderRadius: "5px", alignItems: "center" }}
              onDoubleClick={editingFields ? handleDoubleClick : undefined}
              onChange={(e) => setSelectedFile(e.target.value)}
            />
            <br></br>
            {editingFields ? 'Doble click sobre la imagen para cambiarla' : undefined}
            {"\n"}&nbsp;
            {"\n"}&nbsp;
            {editingFields && (
              <Stack direction="row" spacing={2}>
                {/* <IconButton color="white" aria-label="Editar imagen" onClick={handleImageEdit}><EditIcon /></IconButton> */}
                <IconButton
                  color="white"
                  aria-label="Eliminar"
                  style={{
                    backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                    borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                    width: "40px", // Ajusta el ancho según tus necesidades
                    height: "40px" // Ajusta la altura según tus necesidades
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
            )}
            <SoftBox mb={6}>
              {editingFields ? (
                <SoftInput
                  rows={4}
                  fullWidth
                  value={editedObservacion}
                  onChange={(e) => setEditedObservacion(e.target.value)}
                />
              ) : (
                <SoftTypography variant="body2" color="black">
                  {previewObservacion}
                </SoftTypography>
              )}
            </SoftBox>
            <Stack direction="row" spacing={2}>
              {editingFields ? (
                <>
                <SoftButton
                  type="primary"
                  color="primary"
                  onClick={handleSaveImage}
                  >
                    Guardar
                  </SoftButton>
                </>
              ) : (
                <>
                  <IconButton
                    color="primary"
                    aria-label="Editar observación"
                    onClick={handleEditModalFields}
                    style={{
                      backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                      borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                      width: "40px", // Ajusta el ancho según tus necesidades
                      height: "40px" // Ajusta la altura según tus necesidades
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(ImagenId)}
                    style={{
                      backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                      borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                      width: "40px", // Ajusta el ancho según tus necesidades
                      height: "40px" // Ajusta la altura según tus necesidades
                    }}
                  >
                    <Delete />
                  </IconButton>
                </>
              )}
              <SoftButton onClick={setCerrarModal}>Cerrar</SoftButton>
            </Stack>
          </SoftBox>
        </DialogContent>
        </>
      </Dialog>
      <Dialog
        open={modalOpenAdd}
        onClose={closePreviewImage}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        maxWidth="md"
        fullWidth
      >
        <>
        <DialogContent dividers>
        <Space direction="vertical" style={{ width: "100%" }} fullWidth>
            <Upload
              name="imagen"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => handleNewImageChange(file)}
              // onChange={(info) => setNewImage({ ...newImage, imagen: info.file })}
              fullWidth
            >
              {newImage.imagen ? (
                <img src={URL.createObjectURL(newImage.imagen)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <div>Subir Imagen</div>
              )}
            </Upload>
            <Input.TextArea
              placeholder="Observación"
              value={newImage.o}
              onChange={(e) => setNewImage({ ...newImage, observacion: e.target.value })}
              name="observacion"
            />
            <SoftButton type="primary" onClick={handleSaveNewImagen}>
              Guardar
            </SoftButton>
            <SoftButton onClick={setCerrarModalAdd}>Cerrar</SoftButton>
          </Space>
        </DialogContent>
        </>
      </Dialog>

      <Drawer
        title="Nuevo Seguimiento"
        anchor="right"
        size={sized}
        open={drawerVisibleAdd}
        
        onClose={() => setDrawerVisibleAdd(false)}
        footer={
          <Space>
            <SoftButton  onClick={() => setDrawerVisibleAdd(false)}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveNewSeguimiento}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Operador</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newoperador}
                onChange={(values) => setnewoperador(values)}
              >
                {operadores.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.user.username}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Fecha de Registro&nbsp;&nbsp;</strong>
              </SoftTypography>
                <>
                  <SoftInput
                    type="date"
                    label="Fecha de Registro"
                    value={newfecha_registro}
                    onChange={(event) => setnewfecha_registro(event.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  {/* <SoftButton color="info" onClick={() => setEditandoFecha(false)}>Cancelar</SoftButton> */}
                </>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Altura en cms&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                type="number"
                name="altura"
                value={newaltura}
                onChange={(event) => setnewaltura(event.target.value)}
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Prioridad&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newatencion_prioritaria}
                onChange={(values) => setnewatencion_prioritaria(values)}
              >
                {atencion_prioritarias.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Condicion&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newcondicion}
                onChange={(values) => setnewcondicion(values)}
              >
                {condiciones.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nombre}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Arbol&nbsp;&nbsp;</strong>
              </SoftTypography>
              <Select
                // mode="multiple"
                placeholder="Selecciona una categoría"
                style={{ width: '100%' }}
                value={newarbol}
                onChange={(values) => setnewarbol(values)}
              >
                {arboles.map(cat => (
                  <Select.Option key={cat.id} value={cat.id}>
                    {cat.nickname}
                  </Select.Option>
                ))}
              </Select>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Estado&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                // type="text"
                placeholder="Observaciones 
                
                
                
                
                
                
                
                
                
                "
                name="estado_arbol"
                value={newestado_arbol}
                onChange={(event) => setnewestado_arbol(event.target.value)} 
                fullWidth
              />
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
            </SoftBox>
          </form>
      </Drawer>
    </>
    
  );
}

export default TableSeguimiento;
