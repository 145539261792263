import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import Divider from "@mui/material/Divider";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import apiUrl from "domain";
import { Drawer, Input, Upload, Space } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Delete, Add } from "@mui/icons-material";
import { UserContext } from "App";
import SinLogo from "assets/images/sin-logo.png"
import { useMediaQuery } from "@mui/material";

function Logo() {
  const user_id = useContext(UserContext);
  const [dataGaleria, setdataGaleria] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [perfilEdit, setPerfilEdit] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newPerfil, setNewPerfil] = useState({
    cargo: "",
    nombre: "",
    descripcion: "",
    imagen: null,
  });
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const fetchQuienes = async () => {
    try {
      const response = await fetch(apiUrl + "/api/logo/?format=json");
      const data = await response.json();
      setdataGaleria(data);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchQuienes();
  }, []);

  const openDrawer = (perfil) => {
    setPerfilEdit(perfil);
    setDrawerVisible(true);
  };

  const openAddDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const saveChanges = async (perfil) => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("imagen_banner", selectedFile);
      formDataAdd.append("logo_nombre", perfil.logo_nombre);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/logo/update/${perfil.id}/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchQuienes();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    closeDrawer();
  };

  const handleDelete = (perfil) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar la imagen " + perfil.titulo + "?");
    if (confirmDelete) {
      fetch(apiUrl + `/api/logo/delete/${perfil.id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchQuienes();
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const handleNewPerfilChange = (e) => {
    const { name, value } = e.target;
    setNewPerfil({ ...newPerfil, [name]: value });
  };

  const handleNewPerfilImageChange = (file) => {
    setNewPerfil({ ...newPerfil, imagen: file });
  };

  const handleSaveNewPerfil = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("imagen_banner", newPerfil.imagen_banner);
      formDataAdd.append("logo_nombre", newPerfil.logo_nombre);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/logo/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchQuienes();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    closeDrawer();
  };

  return (
    <>
      <SoftBox mb={3}>
        <Card>
          <SoftBox p={2}>
            <IconButton
              onClick={openAddDrawer}
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
                <Add />
            </IconButton>
            <br></br>
            {"\n"}&nbsp;
            <Grid container spacing={3} justifyContent={isDesktop ? "center" : "left"}>
              {dataGaleria.map((perfil) => (
                <Grid key={perfil.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <DefaultProjectCard
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    image={ perfil.imagen_banner ? `${apiUrl}${perfil.imagen_banner}` : `${apiUrl}${SinLogo}`}
                    label={
                        <span style={{ color: 'info' }}>{perfil.logo_nombre}</span> // Cambia el color del texto a rojo
                    }
                    action={{
                      type: "internal",
                    }}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                    <IconButton
                      onClick={() => openDrawer(perfil)}
                      style={{
                        backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                        borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                        width: "40px", // Ajusta el ancho según tus necesidades
                        height: "40px" // Ajusta la altura según tus necesidades
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(perfil)}
                      style={{
                        backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                        borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                        width: "40px", // Ajusta el ancho según tus necesidades
                        height: "40px" // Ajusta la altura según tus necesidades
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                  {dataGaleria.indexOf(perfil) < dataGaleria.length - 1 && (
                    <Divider orientation="vertical" sx={{ mx: 0 }} />
                  )}
                </Grid>
              ))}
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Drawer
        title="Editar Imagen"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        {perfilEdit && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Upload
              name="imagen"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => setSelectedFile(file)}
              onChange={(info) => {
                if (info.file.status === "done") {
                  setSelectedFile(info.file.originFileObj);
                }
              }}
            >
              {selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <img src={`${apiUrl}${perfilEdit.imagen_banner}`} alt="avatar" style={{ width: "100%" }} />
              )}
            </Upload>
            <Input
              placeholder="Nombre Logo"
              value={perfilEdit.logo_nombre}
              onChange={(e) => setPerfilEdit({ ...perfilEdit, logo_nombre: e.target.value })}
            />
            <Button type="primary" onClick={() => saveChanges(perfilEdit)}>
              Guardar
            </Button>
          </Space>
        )}
        {!perfilEdit && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Upload
              name="imagen_banner"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => handleNewPerfilImageChange(file)}
            >
              {newPerfil.imagen_banner ? (
                <img src={URL.createObjectURL(newPerfil.imagen_banner)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <div>Subir Imagen</div>
              )}
            </Upload>
            <Input
              placeholder="Nombre Logo"
              value={newPerfil.logo_nombre}
              onChange={handleNewPerfilChange}
              name="logo_nombre"
            />
            <Button type="primary" onClick={handleSaveNewPerfil}>
              Guardar
            </Button>
          </Space>
        )}
      </Drawer>
    </>
  );
}

export default Logo;
