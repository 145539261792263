import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import apiUrl from "domain";
import axios from "axios";
import { Drawer, Input, Upload, Space } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import { Delete, Add, Save } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import { UserContext } from "App";

function Equipo() {
  const user_id = useContext(UserContext);
  const [dataEquipo, setdataEquipo] = useState([]);
  const [TituloEquipo, setTituloEquipo] = useState(null);
  const [textoEquipo, setTextoEquipo] = useState(null);
  const [idData, setidData] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [perfilEdit, setPerfilEdit] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newPerfil, setNewPerfil] = useState({
    cargo: "",
    nombre: "",
    descripcion: "",
    imagen: null,
  });

  const [tituloEquipoEdit, setTituloEquipoEdit] = useState(null);
  const [textoEquipoEdit, setTextoEquipoEdit] = useState(null);
  const [editandoDescripcion, setEditandoDescripcion] = useState(false);

  const fetchQuienes = async () => {
    try {
      const response = await fetch(apiUrl + "/api/quienessomos/?format=json");
      const data = await response.json();
      setidData(data[0].id);
      setTituloEquipo(data[0].titulo_equipo);
      setTextoEquipo(data[0].descripcion_equipo);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchBgImage = async () => {
    try {
      const response = await fetch(apiUrl + "/api/equipo/?format=json");
      const data = await response.json();
      setdataEquipo(data);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchBgImage();
    fetchQuienes();
  }, []);

  const openDrawer = (perfil) => {
    setPerfilEdit(perfil);
    setDrawerVisible(true);
  };

  const openAddDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const saveChanges = async (perfil) => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("imagen", selectedFile);
      formDataAdd.append("cargo", perfil.cargo);
      formDataAdd.append("nombre", perfil.nombre);
      formDataAdd.append("descripcion", perfil.descripcion);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/equipo/update/${perfil.id}/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchBgImage();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    closeDrawer();
  };

  const handleDelete = (perfil) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar a " + perfil.nombre + "?");
    if (confirmDelete) {
      fetch(apiUrl + `/api/equipo/delete/${perfil.id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchBgImage();
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const handleNewPerfilChange = (e) => {
    const { name, value } = e.target;
    setNewPerfil({ ...newPerfil, [name]: value });
  };

  const handleNewPerfilImageChange = (file) => {
    setNewPerfil({ ...newPerfil, imagen: file });
  };

  const handleSaveNewPerfil = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("imagen", newPerfil.imagen);
      formDataAdd.append("cargo", newPerfil.cargo);
      formDataAdd.append("nombre", newPerfil.nombre);
      formDataAdd.append("descripcion", newPerfil.descripcion);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/equipo/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchBgImage();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    closeDrawer();
  };

  const openEditMode = () => {
    setEditandoDescripcion(true);
  };

  const ocancelEditMode = () => {
    setEditandoDescripcion(false);
  };

  const closeEditMode = () => {
    setEditandoDescripcion(false);
    // Restablecer los valores originales en caso de cancelación
    setTituloEquipoEdit(TituloEquipo);
    setTextoEquipoEdit(textoEquipo);
  };

  const saveChangesDescripcion = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("titulo_equipo", TituloEquipo);
      formDataAdd.append("descripcion_equipo", textoEquipo);
      formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/equipo/descripcion/update/${idData}/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      closeEditMode()
      fetchBgImage(); // Actualiza los textos después de guardar
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    closeDrawer();
  };

  return (
    <>
      <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
          <SoftBox mb={0.5} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {editandoDescripcion ? (
              <>
                <SoftInput
                  value={TituloEquipo}
                  onChange={(e) => setTituloEquipo(e.target.value)}
                />
                <br></br>
                <SoftInput
                  value={textoEquipo}
                  onChange={(e) => setTextoEquipo(e.target.value)}
                />
              </>
            ) : (
              <>
                <SoftTypography variant="h6" fontWeight="medium">
                  {TituloEquipo}
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  {textoEquipo}
                </SoftTypography>
              </>
            )}
            <IconButton
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              {editandoDescripcion ? <><Save onClick={saveChangesDescripcion} /><CancelIcon onClick={ocancelEditMode} /></> : <EditIcon onClick={openEditMode}/>}
            </IconButton>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <IconButton
              onClick={openAddDrawer}
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
                <Add />
            </IconButton>
            <Grid container spacing={3}>
              {dataEquipo.map((perfil) => (
                <Grid key={perfil.id} item xs={12} md={6} xl={3}>
                  <DefaultProjectCard
                    image={`${apiUrl}${perfil.imagen}`}
                    label={
                        <span style={{ color: 'info' }}>{perfil.cargo}</span> // Cambia el color del texto a rojo
                    }
                    title={perfil.nombre}
                    description={perfil.descripcion}
                    action={{
                      type: "internal",
                    }}
                  />
                  <IconButton
                    onClick={() => openDrawer(perfil)}
                    style={{
                      backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                      borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                      width: "40px", // Ajusta el ancho según tus necesidades
                      height: "40px" // Ajusta la altura según tus necesidades
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDelete(perfil)}
                    style={{
                      backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                      borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                      width: "40px", // Ajusta el ancho según tus necesidades
                      height: "40px" // Ajusta la altura según tus necesidades
                    }}
                  >
                    <Delete />
                  </IconButton>
                  {dataEquipo.indexOf(perfil) < dataEquipo.length - 1 && (
                    <Divider orientation="vertical" sx={{ mx: 0 }} />
                  )}
                </Grid>
              ))}
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Drawer
        title="Editar Perfil"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        {perfilEdit && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => setSelectedFile(file)}
              onChange={(info) => {
                if (info.file.status === "done") {
                  setSelectedFile(info.file.originFileObj);
                }
              }}
            >
              {selectedFile ? (
                <img src={URL.createObjectURL(selectedFile)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <img src={`${apiUrl}${perfilEdit.imagen}`} alt="avatar" style={{ width: "100%" }} />
              )}
            </Upload>
            <Input
              placeholder="Cargo"
              value={perfilEdit.cargo}
              onChange={(e) => setPerfilEdit({ ...perfilEdit, cargo: e.target.value })}
            />
            <Input
              placeholder="Nombre"
              value={perfilEdit.nombre}
              onChange={(e) => setPerfilEdit({ ...perfilEdit, nombre: e.target.value })}
            />
            <Input.TextArea
              placeholder="Descripción"
              value={perfilEdit.descripcion}
              onChange={(e) => setPerfilEdit({ ...perfilEdit, descripcion: e.target.value })}
            />
            <SoftButton color="primary" type="primary" onClick={() => saveChanges(perfilEdit)}>
              Guardar
            </SoftButton>
          </Space>
        )}
        {!perfilEdit && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => handleNewPerfilImageChange(file)}
            >
              {newPerfil.imagen ? (
                <img src={URL.createObjectURL(newPerfil.imagen)} alt="avatar" style={{ width: "100%" }} />
              ) : (
                <div>Subir Imagen</div>
              )}
            </Upload>
            <Input
              placeholder="Cargo"
              value={newPerfil.cargo}
              onChange={handleNewPerfilChange}
              name="cargo"
            />
            <Input
              placeholder="Nombre"
              value={newPerfil.nombre}
              onChange={handleNewPerfilChange}
              name="nombre"
            />
            <Input.TextArea
              placeholder="Descripción"
              value={newPerfil.descripcion}
              onChange={handleNewPerfilChange}
              name="descripcion"
            />
            <SoftButton type="primary" onClick={handleSaveNewPerfil}>
              Guardar
            </SoftButton>
          </Space>
        )}
      </Drawer>
    </>
  );
}

export default Equipo;
