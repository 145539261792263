// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-8wqzlf-MuiGrid-root {
    margin: 8px !important;
  }`, "",{"version":3,"sources":["webpack://./src/layouts/arbol/seguimiento_imagen.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;EACxB","sourcesContent":[".css-8wqzlf-MuiGrid-root {\n    margin: 8px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
