/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React context
import { useSoftUIController, setLayout } from "context";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  useEffect(() => {
    // const script = document.createElement('script');
    // script.innerHTML = `
    //   !function(w, d, s, ...args){
    //     var div = d.createElement('div');
    //     div.id = 'aichatbot';
    //     d.body.appendChild(div);
    //     w.chatbotConfig = args;
      
    //     var f = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s);
    //     j.defer = true;
    //     j.type = 'module';
    //     j.src = 'https://aichatbot.sendbird.com/index.js';
    //     f.parentNode.insertBefore(j, f);
    //   }(window, document, 'script', 'A616AFDE-131D-4CC3-BD33-0DFF452AC3C8', 'onboarding_bot');
    // `;
    // document.body.appendChild(script);

    // return () => {
    //   // Eliminar el script cuando el componente se desmonte
    //   document.body.removeChild(script);
    // };
  }, []);

  return (
    <SoftBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </SoftBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
