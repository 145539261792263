import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import apiUrl from "domain";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { UserContext } from "App";


function About() {
  const user_id = useContext(UserContext);
  const [ImageQuienes, setImageQuienes] = useState(null);
  const [TituloQuienes, setTituloQuienes] = useState(null);
  const [textoQuienes, setTextoQuienes] = useState(null);
  const [idData, setidData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagenFile, setImagen] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);

  useEffect(() => {
    const fetchBgImage = async () => {
      try {
        const response = await fetch(apiUrl + "/api/quienessomos/?format=json");
        const data = await response.json();
        setidData(data[0].id);
        setImageQuienes(data[0].imagen);
        setTituloQuienes(data[0].titulo);
        setTextoQuienes(data[0].descripcion);
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    };

    fetchBgImage();
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/quienes-somos/edit/${idData}`,
        {
          titulo: TituloQuienes,
          descripcion: textoQuienes,
        }
      );
      setEditMode(false); // Salir del modo de edición después de guardar
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSaveImage = async () => {
    try {
      const formData = new FormData();
      formData.append("imagen", selectedFile);
      const response = await axios.post(
        `${apiUrl}/api/quienes-somos/edit-imagen/${idData}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsImageChanged(false);
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  const handleCancel = () => {
    setEditMode(false); // Salir del modo de edición
  };

  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      setImagen(URL.createObjectURL(file));
      setIsImageChanged(true);
    };
    input.click();
  };

  return (
    <>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SoftBox display="flex" flexDirection="column" height="100%">
              {!editMode && (
                <IconButton
                  onClick={() => setEditMode(true)}
                  style={{
                    backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                    borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                    width: "40px", // Ajusta el ancho según tus necesidades
                    height: "40px" // Ajusta la altura según tus necesidades
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                {editMode ? (
                  <SoftInput
                    value={TituloQuienes}
                    onChange={(e) => setTituloQuienes(e.target.value)}
                    fullWidth
                  />
                ) : (
                  TituloQuienes
                )}
              </SoftTypography>
              <SoftBox mb={6}>
                {editMode ? (
                  <SoftInput
                    value={textoQuienes}
                    onChange={(e) => setTextoQuienes(e.target.value)}
                    fullWidth
                    multiline
                  />
                ) : (
                  <SoftTypography variant="body2" color="text">
                    {textoQuienes &&
                    textoQuienes.split("\n").map((linea, index) => (
                      <React.Fragment key={index}>
                        {linea}
                        <br />
                      </React.Fragment>
                    ))}
                  </SoftTypography>
                )}
              </SoftBox>
              {editMode && (
                <>
                <IconButton
                style={{
                  backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                  borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                  width: "40px", // Ajusta el ancho según tus necesidades
                  height: "40px" // Ajusta la altura según tus necesidades
                }}
                >
                  <SaveIcon color="primary" onClick={handleSave} />
                </IconButton>
                <IconButton
                  style={{
                    backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                    borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                    width: "40px", // Ajusta el ancho según tus necesidades
                    height: "40px" // Ajusta la altura según tus necesidades
                  }}
                >
                  <CancelIcon color="secundary" onClick={handleCancel} />
                </IconButton>
                </>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
            <SoftBox
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="info"
              borderRadius="lg"
              variant="gradient"
            >
              <SoftBox
                component="img"
                src={isImageChanged ? imagenFile : `${apiUrl}${ImageQuienes}`}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%"
                onDoubleClick={handleDoubleClick}
              />
              <SoftBox component="img" src={`${apiUrl}${ImageQuienes}`} alt="rocket" width="100%" pt={3} />
              {isImageChanged && (
                <SoftButton variant="contained" onClick={handleSaveImage}>
                  Guardar Imagen
                </SoftButton>
              )}
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
}

export default About;
