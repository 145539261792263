import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from 'antd';
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton"
import SoftAvatar from "components/SoftAvatar";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";
import profile from "assets/images/profile.avif";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ClearIcon from '@mui/icons-material/Clear';
import { Drawer, Input, Upload, Space, Select, DatePicker } from "antd";
import SoftInput from "components/SoftInput";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

let icono;

const authorsTableData = {
  columns: [
    { name: "ID", align: "center" },
    { name: "Imagen", align: "center" },
    { name: "Usuario", align: "left" },
    { name: "Nombre", align: "left" },
    { name: "Activo", align: "left" },
    // { name: "RolIcono", align: "right" },
    { name: "Rol", align: "left" },
    { name: "Ultimoingreso", align: "left" },
    { name: "Creado", align: "center" },
    // { name: "Seguimientos", align: "center" },
    { name: "opciones", align: "center" },
    //{ name: "Acción", align: "center" },
  ],
};

function AssignPermissions() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState();
  const [dataUser, setDataUser] = useState();
  const navigate = useNavigate();
  const [size, setSize] = useState();
  const [ids, setId] = useState();
  const [drawerVisibleEdit, setDrawerVisibleEdit] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [permiso, setPermiso] = useState();
  const [activeMe, setActiveMe] = useState();
  const [autorMe, setAutorMe] = useState();
  const [initialData, setInitialData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [nombreUsuario, setnombreUsuario] = useState('');

  const fetchUser = async () => {
    try {
      const response = await fetch(apiUrl + "/api/user/" + user_id + "/?format=json");
      const data = await response.json();
      setnombreUsuario(data[0].user.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  
  useEffect(() => {
    fetchUser();
  }, []);

  const showModal = (data) => {
      setIsModalOpen(true);
      setmodalData(data);
  };
  const handleOk = () => {
      setIsModalOpen(false);
  };
  const handleCancel = () => {
      setIsModalOpen(false);
  };

  const fetchOperadores = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/group/`);
      const data = await response.json();
      setGrupos(data);
    } catch (error) {
      console.error("Error fetching categorias:", error);
    }
  };

  const fetchTableProfile = async () => {
    try {
      const response = await fetch(apiUrl + "/api/users/?format=json");
      //console.log("response", response);
      const data = await response.json();
      console.log('data', data[0]);
      const formattedData = data.map(item => {
        // Obtenemos la fecha en formato YYYY-MM-DD
        const ultimoingreso = new Date(item.user.date_joined).toISOString().split('T')[0];
        const creado = new Date(item.created_at).toISOString().split('T')[0];
        const usuario = <SoftTypography type="primary" style={{ fontSize: '14px' }}>{item.user.username}</SoftTypography>;
        const nombre = <SoftTypography type="primary" style={{ fontSize: '14px' }}>{item.user.first_name} {item.user.last_name}</SoftTypography>;
        const is_active = <Switch checked={item.user.is_active}/>
        const rolText = item.user.groups && item.user.groups[0] && item.user.groups[0]['name'] ? item.user.groups[0]['name'] : "Sin Rol";
        console.log()
        switch (rolText) {
          case 'Usuario':
            icono = <PersonIcon />;
            break;
          case 'Administrador':
            icono = <SupervisorAccountIcon />;
            break;
          case 'Operador':
            icono = <ManageAccountsIcon />;
            break;
          default:
            icono = <ClearIcon />;
        }
        const rol = (
          <div>
            <SoftTypography type="primary" style={{ fontSize: '14px' }}>{rolText}</SoftTypography>
          </div>
        );

        //const rol = <SoftTypography type="primary" style={{ fontSize: '14px' }}>{item.user.groups[0]?.name || 'Sin Rol'}</SoftTypography>;
        const imagenData = <SoftAvatar src={item.avatar ? `${apiUrl}${item.avatar}` : profile} alt={item.user.username} size="sm" variant="rounded" />;
        const opcionesData = (
          <>
            <IconButton
              onClick={() => handleEdit(item)}
              aria-label="Editars"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <EditIcon />
            </IconButton>
            {/* <IconButton
              onClick={() => handleDelete(item)}
              aria-label="Eliminar"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <DeleteIcon />
            </IconButton> */}
          </>
        );
        return {
          ID: item.id.toString(),
          Imagen: imagenData,
          Usuario: usuario,
          Nombre: nombre, // Aquí asignamos la fecha formateada
          Activo: is_active,
          // RolIcono: icono,
          Rol: rolText,
          Ultimoingreso: ultimoingreso,
          Creado: creado,
          // Seguimientos: item.seguimientos_count.toString(),
          opciones: opcionesData,
        };
      });
      setCounterData(formattedData);
      setInitialData(formattedData);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchTableProfile();
  }, []);

  const closeEditUser = () => {
    setDrawerVisibleEdit(false);
  };
  const handleEdit = (data) => {
    fetchOperadores();
    setDataUser([data]);
    setId(data.id);
    setSize("large");
    setActiveMe(data.user.is_active)
    setAutorMe(data.es_autor)
    setPermiso(data.user.groups[0].name);
    setDrawerVisibleEdit(true);
  };

  const handleSave = async () => {
    try {
      console.log("dataUser.id", ids);
      const response = await fetch(apiUrl + `/api/grupo/update/${ids}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          is_active: activeMe,
          es_autor: autorMe,
          rol: permiso,
        })
      });
      fetchTableProfile();
      if (response.ok) {
        console.log('Data updated successfully');
      } else {
        console.error('Failed to update data');
      }
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setDrawerVisibleEdit(false);
    }
  };

  const handleClearConsecutivo = () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };

  const handleConsecutivoChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    const filteredData = initialData.filter(row => {
      const nombre = row.Usuario.props.children.toString().toLowerCase();
      return nombre.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  const handleClearNombre= () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };

  const handleNombreChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    const filteredData = initialData.filter(row => {
      const nombre = row.Nombre.props.children.toString().toLowerCase();
      return nombre.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  const renderFilters = () => (
    <SoftBox>
      <Input
        placeholder="Usuario"
        onChange={(event) => handleConsecutivoChange(event)}
        onClear={handleClearConsecutivo}
        style={{ width: 200, margin: '0 10px' }}
      />
      <Input
        placeholder="Nombre"
        onChange={(event) => handleNombreChange(event)}
        onClear={handleClearNombre}
        style={{ width: 200, margin: '0 10px' }}
      />
    </SoftBox>
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar username={nombreUsuario} />
        <DashboardTable>
            <SoftBox mb={3}>
              <Card>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SoftTypography type="primary" style={{ fontSize: '14px' }}>
                      <SupervisorAccountIcon /> Administrador &nbsp; 
                      <PersonIcon /> Usuario &nbsp; 
                      <ManageAccountsIcon /> Operador &nbsp; 
                      <ClearIcon /> Sin Rol &nbsp; 
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SoftTypography variant="h6">
                      Usuarios
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                  sx={{
                      "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                      },
                  }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Filtros por: &nbsp;&nbsp;{renderFilters()}
                  <Table columns={columns} rows={counterData} />
                  </SoftBox>
              </Card>
            </SoftBox>
        </DashboardTable>
      </DashboardLayout>
      <Drawer
        title="Editar Usuario"
        anchor="right"
        size={size}
        open={drawerVisibleEdit}
        onClose={closeEditUser}
        footer={
          <Space>
            <SoftButton  onClick={closeEditUser}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSave}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            {dataUser && dataUser.map((data) => (
                  <Card key={data.id}>
                    <SoftBox p={2}>
                      <SoftAvatar
                        src={data.avatar ? `${apiUrl}${data.avatar}` : profile}
                        alt={data.user.username}
                        size="sm"
                        variant="rounded"
                      />
                      <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                        {data.user.first_name} {data.user.last_name}
                      </SoftTypography>
                      <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                        {data.user.username}
                      </SoftTypography>
                      <SoftBox p={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6}>
                            <SoftBox display="flex" flexDirection="column" height="100%">
                              {/* <Switch checked={data.user.active} onChange={() => setFollowsMe(!followsMe)} /> */}
                              <SoftTypography variant="body2" color="text">
                                Usuario Activo?
                              </SoftTypography>
                              <Switch checked={activeMe} onChange={() => setActiveMe(!activeMe)} />
                              <SoftBox mb={6}>
                                <SoftTypography variant="body2" color="text">
                                  Seleccionar Rol del Usuario
                                </SoftTypography>
                                {activeMe ? (
                                  <Select
                                    placeholder="Selecciona un Permiso"
                                    style={{ width: '100%' }}
                                    value={permiso}
                                    onChange={(values) => setPermiso(values)}
                                  >
                                    {grupos.map(cat => (
                                      <Select.Option key={cat.id} value={cat.name}>
                                        {cat.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                ) : (
                                  <Select disabled={true} placeholder="No disponible" style={{ width: '100%' }} />
                                )}
                              </SoftBox>
                              <SoftTypography variant="body2" color="text">
                                Es Autor?
                              </SoftTypography>
                              <Switch checked={autorMe} onChange={() => setAutorMe(!autorMe)} />
                            </SoftBox>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                    {dataUser.indexOf(data) < dataUser.length - 1 && (
                      <Divider orientation="vertical" sx={{ mx: 0 }} />
                    )}
                  </Card> 
                  
                ))}
          </form>
      </Drawer>
    </>
  );
}

export default AssignPermissions;
