
// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";
import ArbolList from "layouts/arbol/list";
import apiUrl_home from "home_domain";
import ListaBlogs from "layouts/blogs";
// import Seguimientos from "layouts/seguimientos";
import QuienesSomos from "layouts/quienessomos";
import Galeria from "layouts/galeria";
import ArbolTablasAll from "layouts/arbol/allArbol";
// const user_id = localStorage.getItem("user_id");
import WhatsAppComponent from "layouts/chat";
import { CgProfile } from "react-icons/cg";
import { IoHomeOutline } from "react-icons/io5";
import { GrGroup, GrGallery } from "react-icons/gr";
import { SiReadthedocs } from "react-icons/si";

import { RxDashboard } from "react-icons/rx";
import { LuTrees } from "react-icons/lu";
import { TbTrees, TbTableOptions } from "react-icons/tb";
import { BsFolderSymlink, BsQrCode } from "react-icons/bs";
import { BiImageAdd } from "react-icons/bi";
import { CiCircleList } from "react-icons/ci";
import { FaComment, FaWhatsapp, FaFileSignature  } from "react-icons/fa";
import { MdContactEmergency } from "react-icons/md";
import AssignPermissions from "layouts/usuarios";
import Opciones from "layouts/arbol/sections";
import Logos from "pages/sections";
import Terminos from "layouts/terminos";
import ListaTestimonios from "pages/sections/Testimonios";
import HeaderImage from "layouts/header";
import PreguntasContacto from "layouts/contactanos";
import { LiaImagesSolid } from "react-icons/lia";
import NumeroWhatsapp from "layouts/whatsapp";
import ListaComprobantes from "layouts/comprobantes/allComprobantes";
import FirmaDigital from "layouts/firma";

const routes = [
  {
    type: "title",
    title: "Cuenta",
    key: "cuenta-pages",
    rol: "All",
  },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    route: "/",
    icon: <CgProfile size="20px" />,
    component: <Profile />,
    noCollapse: true,
    rol: "All"
  },
  {
    type: "collapse",
    name: "Header",
    key: "header",
    route: "/header",
    icon: <LiaImagesSolid  size="20px" />,
    component: <HeaderImage />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    route: "/usuarios",
    icon: <CgProfile size="20px" />,
    component: <AssignPermissions />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "title",
    title: "Landing",
    key: "general-pages",
    rol: "Administrador",
  },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: `${apiUrl_home}`,
    icon: <IoHomeOutline size="20px" />,
    // component: <Dashboard />,
    noCollapse: true,
    rol: "Administrador"
  },
  //{
  //  type: "collapse",
  //  name: "Quienes somos",
  //  key: "quienes",
  //  route: "/quienes-somos",
  //  icon: <GrGroup size="20px" />,
  //  component: <QuienesSomos />,
  //  noCollapse: true,
  //  rol: "Administrador"
  //},
  //{
  //  type: "collapse",
  //  name: "Galeria",
  //  key: "galeria",
  //  route: "/galeria",
  //  icon: <GrGallery size="20px" />,
  //  component: <Galeria />,
  //  noCollapse: true,
  //  rol: "Administrador"
  //},
  {
    type: "collapse",
    name: "Logo",
    key: "logo",
    route: "/logo",
    icon: <BiImageAdd  size="20px" />,
    component: <Logos />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "collapse",
    name: "Preguntas Frecuentes",
    key: "preguntas",
    route: "/preguntas",
    icon: <MdContactEmergency   size="20px" />,
    component: <PreguntasContacto />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "collapse",
    name: "Ter. y Cond.",
    key: "terminos",
    route: "/terminos-condiciones",
    icon: <CiCircleList   size="20px" />,
    component: <Terminos />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "title",
    title: "General",
    key: "general-pages",
    rol: "All",
  },
  //{
  //  type: "collapse",
  //  name: "Dashboard",
  //  key: "dashboard",
  //  route: "/dashboard",
  //  icon: <RxDashboard size="20px" />,
  //  component: <Dashboard />,
  //  noCollapse: true,
  //  rol: "All"
  //},
  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    route: "/blogs",
    icon: <BsFolderSymlink size="20px" />,
    component: <ListaBlogs />,
    noCollapse: true,
    rol: "Administrador"
  },
  //{
  // type: "collapse",
  //  name: "Testimonios",
  //  key: "testimonios",
  //  route: "/testimonios",
  //  icon: <FaComment size="20px" />,
  //  component: <ListaTestimonios />,
  //  noCollapse: true,
  //  rol: "Administrador"
  //},
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   icon: <Document size="12px" />,
  //   component: <SignIn />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Numero Whatsapp",
    key: "numerowhatsapp",
    route: "/numerowhatsapp",
    icon: <FaWhatsapp size="20px" />,
    component: <NumeroWhatsapp />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "collapse",
    name: "QR",
    key: "QR",
    route: "/QR",
    icon: <BsQrCode  size="20px" />,
    component: <WhatsAppComponent />,
    noCollapse: true,
    rol: "Administrador"
  },
  {
    type: "title",
    title: "Arboles",
    key: "lista-arboles",
    rol: "All",
  },
  {
    type: "collapse",
    name: "Mis Arboles",
    key: "misarboles",
    route: "/mis-arboles",
    icon: <TbTrees size="20px" />,
    component: <ArbolList />,
    noCollapse: true,
    rol: "Usuario"
  },
  {
    type: "collapse",
    name: "Arboles",
    key: "arboles",
    route: "/arboles",
    icon: <LuTrees size="20px" />,
component: <ArbolTablasAll />,
    noCollapse: true,
    rol: "Operador"
  },
  {
    type: "collapse",
    name: "Comprobantes",
    key: "Comprobantes",
    route: "/comprobantes",
    icon: <SiReadthedocs size="20px" />,
    component: <ListaComprobantes />,
    noCollapse: true,
    rol: "Usuario"
  },
  {
    type: "collapse",
    name: "Opciones",
    key: "opciones",
    route: "/opciones",
    icon: <TbTableOptions size="20px" />,
    component: <Opciones />,
    noCollapse: true,
    rol: "Administrador"
  },

  {
    type: "collapse",
    name: "Firmar",
    key: "firma",
    route: "/firma",
    icon: <FaFileSignature size="20px" />,
    component: <FirmaDigital />,
    noCollapse: true,
    rol: "Administrador"
  },
];

export default routes;
