import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import breakpoints from "assets/theme/base/breakpoints";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import curved0 from "assets/images/curved-images/curved0.jpg";
import apiUrl from "domain";
import { Button } from "@mui/material";
import axios from "axios";
import { UserContext } from "App";
import SoftButton from "components/SoftButton";
import { FaInfoCircle } from "react-icons/fa";

function Header() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [nombreProfile, setnombreProfile] = useState(null);
  const [apellidoProfile, setapellidoProfile] = useState(null);
  const [usernameProfile, setusernameProfile] = useState(null);
  const [imagen, setImagen] = useState(null);
  const [editModeImagen, setEditModeImagen] = useState(false);
  const [imagenPerfil, setimagenPerfil] =  useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editModeInfo, setEditModeInfo] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const user_id = useContext(UserContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordMismatchTrue, setpasswordMismatchTrue] = useState(false);
  const [messagesResponse, setMessagesResponse] = useState("");
  const [messagesResponsecolor, setMessagesResponsecolor] = useState("");

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSavePasswordChange = async () => {
    //Verificar si las contraseñas nuevas coinciden
    if (newPassword !== confirmNewPassword) {
      // setPasswordMismatch(true);
      setMessagesResponse("Las Nuevas contraseñas no coinciden.");
      setMessagesResponsecolor('error')
      return;
    }
    if (!newPassword || !confirmNewPassword || !currentPassword) {
      // setPasswordMismatch(true);
      setMessagesResponse("Alguno de los campos se encuentra vacio.");
      setMessagesResponsecolor('error')
      return;
    }

    try {
      const response = await axios.post(apiUrl + `/api/password-change/${user_id}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: currentPassword, // Asumiendo que operador es el ID del UserProfile seleccionado
          newpassword: newPassword,
          confirmnewpassword: confirmNewPassword,
        })
      });
      setMessagesResponse(response.data.message);
      setMessagesResponsecolor('success')
    } catch (error) {
      setMessagesResponse(error.response.data.message);
      setMessagesResponsecolor('error')
    } finally {
      setpasswordMismatchTrue(true);
    }
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };
  
  const fetchBgImage = async () => {
    try {
      const response = await fetch(apiUrl + "/api/user/" + user_id + "/?format=json");
      const data = await response.json();
      console.log("data[0]", data);
      setnombreProfile(data[0].user.first_name);
      setapellidoProfile(data[0].user.last_name);
      setusernameProfile(data[0].user.username);
      setimagenPerfil(data[0].avatar);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const fetchBannerAdmin = async () => {
    try {
      const response = await fetch(apiUrl + "/api/banner-admin/?format=json");
      const data = await response.json();
      setImagen(data[0].imagen_encabezado);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  const handleImagenSeleccionada = (evento) => {
    const archivo = evento.target.files[0];
    setImagen(URL.createObjectURL(archivo));
  };

  const handleGuardarImagen = async () => {
    const formData = new FormData();
    formData.append("imagen_encabezado", imagen);
    formData.append("csrfmiddlewaretoken", csrfToken);
    try {
      formData.append("avatar", selectedFile);
      const response = await fetch(apiUrl + "/api/user/upload/encabezado/" + user_id + "/", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRFToken": csrfToken
        }
      });
      if (response.ok) {        
        const data = await response.json();
        fetchBgImage();
        setEditModeImagen(false);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error saving avatar:", error);
    }
  };

  useEffect(() => {
    fetchBannerAdmin();
    fetchBgImage();
    // Lógica para obtener el token CSRF del backend
    fetch(apiUrl + "/api/get-csrf-token/")
      .then(response => response.json())
      .then(data => setCsrfToken(data.csrf_token))
      .catch(error => console.error("Error fetching CSRF token:", error));
  }, []);

  useEffect(() => {    
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const handleDoubleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
      setEditMode(true);
    };
    input.click();
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      setImagen(file);
      setEditModeImagen(true);
    };
    input.click();
  };

  const handleSaveChanges = async () => {
    const formData = new FormData();
    formData.append("avatar", selectedFile);
    formData.append("csrfmiddlewaretoken", csrfToken);
    try {
      formData.append("avatar", selectedFile);
      const response = await fetch(apiUrl + "/api/user/upload/" + user_id + "/", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRFToken": csrfToken
        }
      });
      if (response.ok) {        
        const data = await response.json();
        setimagenPerfil(data.avatar_url);
        setEditMode(false);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error saving avatar:", error);
    }
  };

  const handleEditData = () => {
    setEditModeInfo(true);
  };

  const handleEditDataCancel = () => {
    setEditModeInfo(false);
  };

  const handleInputData = async () => {
    const formData = new FormData();
    formData.append('first_name', nombreProfile);
    formData.append('last_name', apellidoProfile);
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }
    const response = await axios.put(apiUrl + "/api/user/" + user_id + "/update/", formData);
    fetchBgImage();
    setEditModeInfo(false);
  };

  const handleCancelarEdicion = () => {
    setImagen(null);
    setEditModeImagen(false);
  };

  const getPasswordColor = (length, section) => {
    if (section === 1 && length > 0) {
      return "#bf352d";
    } else if (section === 2 && length >= 6) {
      return "#c3cb4c";
    } else if (section === 3 && length >= 10) {
      return "#606336";
    }
    return "white";
  };

  return (
    <SoftBox position="relative">
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${imagen ? `${apiUrl}${imagen}` : curved0 })`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <input id="input-imagen" type="file" accept="image/*" onChange={handleImagenSeleccionada} style={{ display: 'none' }} />

        {/* {editModeImagen ? (
          <>
            <IconButton
              variant="contained"
              color="info"
              aria-label="Guardar"
              style={{
                position: 'absolute',
                top: '40px',
                right: '10px',
                backgroundColor: "#ffffff",
                borderRadius: "0.5rem",
                width: "40px",
                height: "40px",
              }}
            >
              <SaveIcon onClick={handleGuardarImagen} />
            </IconButton>
            <IconButton
              variant="contained"
              color="warning"
              aria-label="Cancelar"
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: "#ffffff",
                borderRadius: "0.5rem",
                width: "40px",
                height: "40px"
              }}
            >
              <CancelIcon onClick={handleCancelarEdicion} />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
            variant="contained"
            color="info"
            onClick={handleInputData}
            aria-label="Guardar"
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: "#ffffff",
              borderRadius: "0.5rem",
              width: "40px",
              height: "40px"
            }}
          >
            <EditIcon onClick={handleClick} />
          </IconButton>
          </>
        )} */}
      </SoftBox>
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
          <SoftAvatar
                src={imagenPerfil ? `${apiUrl}${imagenPerfil}` : `${apiUrl}/media/landing/equipo/persona2.jpg`}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
                sx={{ cursor: "pointer" }}
                onDoubleClick={handleDoubleClick}
              />
          </Grid>
          {editMode && (
            <SoftBox mt={2}>
              <Button onClick={handleSaveChanges} color="primary">
                Guardar Avatar
              </Button>
            </SoftBox>
          )}
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <>
                {editModeInfo ? (
                  <>
                    <SoftInput
                      name="first_name"
                      label="Primer Nombre"
                      value={nombreProfile}
                      onChange={(e) => setnombreProfile(e.target.value)}
                    />
                    <SoftInput
                      name="last_name"
                      label="Primer Nombre"
                      value={apellidoProfile}
                      onChange={(e) => setapellidoProfile(e.target.value)}
                    />
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                    <>
                      {usernameProfile}
                    </>
                    </SoftTypography>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={handleInputData}
                      style={{
                        backgroundColor: "#ffffff", 
                        borderRadius: "0.5rem",
                        width: "40px",
                        height: "40px"
                      }}
                      aria-label="Guardar"
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "0.5rem",
                        width: "40px",
                        height: "40px"
                      }}
                      aria-label="Cancelar"
                    >
                      <CancelIcon onClick={handleEditDataCancel} />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <SoftTypography variant="h5" fontWeight="medium">
                    <>
                      {nombreProfile}
                    </>
                    </SoftTypography>
                    <SoftTypography variant="h5" fontWeight="medium">
                    <>
                      {apellidoProfile}
                    </>
                    </SoftTypography>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                    <>
                      {usernameProfile}
                    </>
                    </SoftTypography>
                    &nbsp;
                    <IconButton
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "0.5rem",
                        width: "40px",
                        height: "40px"
                      }}
                      aria-label="editar"
                    >
                      <EditIcon onClick={handleEditData} />
                    </IconButton>
                  </>
                )}
                <SoftButton
                  variant="contained"
                  color="primary"
                  // style={{
                  //   backgroundColor: "#ffffff",
                  //   borderRadius: "0.5rem",
                  //   width: "40px",
                  //   height: "40px",
                  // }}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '80px',
                    backgroundColor: "rgb(131 128 128 / 41%)", // Cambia el color de fondo según tus preferencias
                    borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                    width: "100px", // Ajusta el ancho según tus necesidades
                    height: "40px" // Ajusta la altura según tus necesidades
                  }}
                  onClick={handleOpenModal}
                  aria-label="Cambiar Contraseña"
                >
                  Cambiar Contraseña
                </SoftButton>
              </>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-cambiar-contrasena"
        aria-describedby="modal-cambiar-contrasena"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <SoftBox p={4}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Contraseña actual</strong>
              </SoftTypography>
              <SoftInput
                label="Contraseña Actual"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Nueva contraseña</strong>
                <Tooltip describeChild title="La contraseña debe contener al menos 6 caracteres.">
                  &nbsp;&nbsp;<FaInfoCircle />
                </Tooltip>
              </SoftTypography>
              
              <SoftInput
                label="Nueva Contraseña"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                required
              />
              <SoftBox style={{ display: "flex", alignItems: "center", marginBottom: "1px" }}>
                <SoftBox style={{ flex: 3, height: "5px", borderRadius: "3px", backgroundColor: getPasswordColor(newPassword.length, 1), marginRight: "1px" }}></SoftBox>
                <SoftBox style={{ flex: 3, height: "5px", borderRadius: "3px", backgroundColor: getPasswordColor(newPassword.length, 2), marginRight: "1px" }}></SoftBox>
                <SoftBox style={{ flex: 3, height: "5px", borderRadius: "3px", backgroundColor: getPasswordColor(newPassword.length, 3) }}></SoftBox>
              </SoftBox>
              </>
            </Grid>
            <Grid item xs={12}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong>Confirmar nueva contraseña</strong>
                <Tooltip describeChild title="La contraseña debe contener al menos 6 caracteres.">
                  &nbsp;&nbsp;<FaInfoCircle />
                </Tooltip>
              </SoftTypography>
              <SoftInput
                label="Confirmar Nueva Contraseña"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <SoftButton variant="contained" color="primary" onClick={handleSavePasswordChange}>
                Cambiar Contraseña
              </SoftButton>&nbsp;&nbsp;
              <SoftButton variant="contained" color="error" onClick={handleCloseModal}>
                Cancelar
              </SoftButton>
              {/* {passwordMismatch && (
                <Alert severity="error" onClose={() => setPasswordMismatch(false)}>
                  Las contraseñas nuevas no coinciden.
                </Alert>
              )} */}
              {/* {passwordMismatchTrue && (
                <Alert severity="success" onClose={() => setpasswordMismatchTrue(false)}>
                  Contraseña cambiada correctamente.
                </Alert>
              )} */}
              {messagesResponse && (
                <Alert severity={messagesResponsecolor} onClose={() => setpasswordMismatchTrue(false)}>
                  {messagesResponse}
                </Alert>
              )}
            </Grid>
          </Grid>
          </SoftBox>
        </Card>
      </Modal>
    </SoftBox>
  );
}

export default Header;
