import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import Icon from "@mui/material/Icon";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardTable from "layouts/arbol/data/DashboardTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from 'antd';
import Grid from "@mui/material/Grid";
import SoftButton from "components/SoftButton"
import SoftAvatar from "components/SoftAvatar";
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useParams } from 'react-router-dom';
import { UserContext } from "App";


const authorsTableData = {
  columns: [
    { name: "Operador", align: "center" },
    // { name: "Nickname", align: "left" },
    { name: "Fecharegistro", align: "center" },
    { name: "tipoarbol", align: "center" },
    { name: "fechasiembra", align: "center" },
    { name: "opciones", align: "center" },
    // { name: "Acción", align: "center" },
  ],
};

function TableSeguimiento(id) {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setmodalData] = useState();
  const { numero } = useParams();

  const showModal = (data) => {
      setIsModalOpen(true);
      setmodalData(data);
  };
  const handleOk = () => {
      setIsModalOpen(false);
  };
  const handleCancel = () => {
      setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchTableSeguimiento = async () => {
      try {
        const response = await fetch(apiUrl + "/api/seguimientos/" + numero + "?format=json");
        const data = await response.json();
        const formattedData = data.map(item => {
          // Obtenemos la fecha en formato YYYY-MM-DD
          const registrodDate = new Date(item.fecha_registro).toISOString().split('T')[0];
          const siembraodDate = new Date(item.arbol.fecha_siembra).toISOString().split('T')[0];
          const nombreData = <SoftTypography type="primary" onClick={() => showModal([item])}>{item.arbol.nickname}</SoftTypography>;
          const imagenData = <SoftAvatar src={`${apiUrl}${item.operador.avatar}`} alt={item.arbol.nickname} size="sm" variant="rounded" />;
          const opcionesData = <SoftButton color="primary" textGradient="true" onClick={() => handleSeguimientoClick(item.id)}>Ver</SoftButton>;
          return {
            id: item.id,
            Nickname: nombreData,
            Fecharegistro: registrodDate, // Aquí asignamos la fecha formateada
            tipoarbol: item.arbol.tipo_arbol.nombre,
            //tipoarbol: item.operador.date_of_birth,
            fechasiembra: siembraodDate,
            Operador: imagenData,
            opciones: opcionesData,
          };
        });
        setCounterData(formattedData);
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    };
    fetchTableSeguimiento();
  }, []);

  const handleSeguimientoClick = (itemId) => {
    window.location.href = `/arbol/seguimiento/${itemId}`;
    // navigate(`${apiUrl}/arbol/seguimiento/${itemId}`); // Navigate to TableSeguimiento with itemId
  };
  
  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Seguimientos</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={counterData} />
            </SoftBox>
          </Card>
        </SoftBox>
      </DashboardTable>
    </>
  );
}

export default TableSeguimiento;
