import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import apiUrl from "domain";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import About from "layouts/quienessomos/about";
import Equipo from "layouts/quienessomos/equipo";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { UserContext } from "App";


function QuienesSomos() {
  const user_id = useContext(UserContext);
  const [flows, setFlows] = useState([]);
  const [currentFlow, setCurrentFlow] = useState(null);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [relatedFlows, setRelatedFlows] = useState([]);

  const handleAddFlow = () => {
    const newFlow = {
      keyword: currentKeyword,
      answer: currentAnswer,
      relatedFlows: relatedFlows
    };
    setFlows([...flows, newFlow]);
    setCurrentKeyword('');
    setCurrentAnswer('');
    setRelatedFlows([]);
  };

  const handleAddRelatedFlow = (flowIndex) => {
    const updatedRelatedFlows = [...relatedFlows, flows[flowIndex]];
    setRelatedFlows(updatedRelatedFlows);
  };
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <About />
      </Card>
      <br></br>
      <Card>
        <Equipo />
        {/* <div>
      <h2>Flows</h2>
      {flows.map((flow, index) => (
        <div key={index}>
          <p>Keyword: {flow.keyword}</p>
          <p>Answer: {flow.answer}</p>
          <p>Related Flows: {flow.relatedFlows.map(related => related.keyword).join(', ')}</p>
        </div>
      ))}
      <h2>Add Flow</h2>
      <div>
        <label>Keyword:</label>
        <input type="text" value={currentKeyword} onChange={(e) => setCurrentKeyword(e.target.value)} />
      </div>
      <div>
        <label>Answer:</label>
        <input type="text" value={currentAnswer} onChange={(e) => setCurrentAnswer(e.target.value)} />
      </div>
      <div>
        <label>Related Flows:</label>
        <select multiple onChange={(e) => handleAddRelatedFlow(e.target.selectedIndex)}>
          {flows.map((flow, index) => (
            <option key={index}>{flow.keyword}</option>
          ))}
        </select>
      </div>
      <button onClick={handleAddFlow}>Add Flow</button>
    </div> */}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default QuienesSomos;
