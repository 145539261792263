// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Satoshi-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Satoshi Regular";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
    /* Puedes agregar más propiedades aquí según sea necesario */
}
`, "",{"version":3,"sources":["webpack://./src/font.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,+DAAkD;IAClD,4DAA4D;AAChE","sourcesContent":["@font-face {\n    font-family: \"Satoshi Regular\";\n    src: url(\"Satoshi-Regular.ttf\") format(\"truetype\");\n    /* Puedes agregar más propiedades aquí según sea necesario */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
