import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardTable from "layouts/arbol/data/DashboardTable";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import apiUrl from "domain";
import React, { useEffect, useState, useContext } from "react";
import SoftButton from "components/SoftButton"
import { useNavigate, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Delete, Add, Save } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Drawer, Input, Upload, Space, Select, DatePicker } from "antd";
import SoftInput from "components/SoftInput";
import axios from "axios";
import Mapa from "layouts/arbol/data/mapa";
import { UserContext } from "App";


const authorsTableData = {
  columns: [
    { name: "ID", align: "center" },
    { name: "Nombre", align: "center" },
    { name: "opciones", align: "center" },
  ],
};

function Potrero() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  const [counterData, setCounterData] = useState([]);
  const user_id = useContext(UserContext);
  const [drawerVisibleAdd, setDrawerVisibleAdd] = useState(false);
  const [drawerVisibleEdit, setDrawerVisibleEdit] = useState(false);
  const [size, setSize] = useState();

  // new seguimiento
  const [ids, setId] = useState();
  const [nombre, setNombre] = useState();
  const [newNombre, setNewNombre] = useState([]);
  const [initialData, setInitialData] = useState([]); // Datos originales
  const [searchText, setSearchText] = useState('');

  

  const handleEdit = (data) => {
    setSize("medium");
    setId(data.id);
    setDrawerVisibleEdit(true);
    setNombre(data.nombre);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const showModal = (data) => {
      setIsModalOpen(true);
      setmodalData(data);
  };

  const fetchTableProfile = async () => {
    try {
      const response = await fetch(apiUrl + "/api/potreros/?format=json");
      const data = await response.json();
      const formattedData = data.map(item => {
        const opcionesData = (
          <>
            <IconButton
              onClick={() => handleEdit(item)}
              aria-label="Editars"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(item)}
              aria-label="Eliminar"
              style={{
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
        return {
          ID: item.id.toString(),
          Nombre: item.nombre,
          opciones: opcionesData,
        };
      });
      setCounterData(formattedData);
      setInitialData(formattedData);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchTableProfile();
  }, []);


  useEffect(() => {
    fetchTableProfile();
  }, []);

  const handleSaveNewCondicion = async () => {
    try {
      const csrftoken = getCookie("csrftoken");
      const formDataAdd = new FormData();
      formDataAdd.append("nombre", newNombre);
      //formDataAdd.append("csrfmiddlewaretoken", getCookie("csrftoken"));
      const response = await fetch(`${apiUrl}/api/potrero/create/`, {
        method: "POST",
        mode: 'no-cors',
        credentials: 'include',
        body: formDataAdd,
        headers: {
          "X-CSRFToken": csrftoken,
          'Content-Type':  'application/json',
        },
      });
      fetchTableProfile();
    } catch (error) {
      console.error("Error saving new perfil:", error);
    }
    setDrawerVisibleAdd(false);
    clearData();
  };

  const openAddDrawer = () => {
    setDrawerVisibleAdd(true);
    setSize("medium");
  };

  const clearData = () => {
    setNombre("");
  };

  const handleDelete = (item) => {
    const confirmDelete = window.confirm("¿Seguro que deseas eliminar la Condición " + item.nombre + "?");
    if (confirmDelete) {
      fetch(apiUrl + `/api/potrero/delete/${item.id}/`, {
        method: "DELETE",
      })
        .then(() => {
          fetchTableProfile();
        })
        .catch((error) => console.error("Error deleting counter:", error));
    }
  };

  const handleSaveChange = async () => {
    try {
      const formData = new FormData();
      formData.append('nombre', nombre);
      const response = await axios.post(
        `${apiUrl}/api/potrero/update/${ids}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchTableProfile();
      setDrawerVisibleEdit(false);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      clearData();
    }
  };

  const closeEditArbol = (arbol_id) => {
    setDrawerVisibleEdit(false);
    setLongitud(null);
    setLatitud(null);
  };

  useEffect(() => {
    const filteredData = initialData.filter(row => {
      const nombre = row.Nombre.toString().toLowerCase();
      return nombre.includes(searchText);
    });
    setCounterData(filteredData);
  }, [searchText, initialData]);

  const handleConsecutivoChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchText(inputValue);
    
    const filteredData = initialData.filter(row => {
      const nombre = row.Nombre.toString().toLowerCase();
      return nombre.includes(inputValue);
    });
    setCounterData(filteredData);
  };

  const handleClearConsecutivo = () => {
    setSearchText(''); // Limpiar el texto de búsqueda
  };


  const renderFilters = () => (
    <div>
      <Input
        placeholder="Nombre"
        onChange={(event) => handleConsecutivoChange(event)}
        onClear={handleClearConsecutivo}
        style={{ width: 200, margin: '0 10px' }}
      />
    </div>
  );


  return (
    <>
      <DashboardTable>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Lista de Potreros</SoftTypography>
              <IconButton
              style={{
                position: "abslolute",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ffffff", // Cambia el color de fondo según tus preferencias
                borderRadius: "0.5rem", // Ajusta el radio de borde para que sea circular
                width: "40px", // Ajusta el ancho según tus necesidades
                height: "40px" // Ajusta la altura según tus necesidades
              }}
              onClick={openAddDrawer}
            >
              <SoftTypography variant="h6"></SoftTypography> <Add />
            </IconButton>
            </SoftBox>
            <SoftBox
              style={{ display: 'flex',
                      justifyContent: 'center'
                    }}
            >
              Filtro por: {renderFilters()}
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={counterData} />
              
            </SoftBox>
          </Card>
        </SoftBox>
      </DashboardTable>
      <Drawer
        title="Nuevo Potrero"
        anchor="right"
        size={size}
        open={drawerVisibleAdd}
        
        onClose={() => setDrawerVisibleAdd(false)}
        footer={
          <Space>
            <SoftButton  onClick={() => setDrawerVisibleAdd(false)}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveNewCondicion}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong><br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;Nombre&nbsp;&nbsp;</strong>
              </SoftTypography>
              <SoftInput
                name="nombre"
                value={newNombre}
                onChange={(event) => setNewNombre(event.target.value)} 
                fullWidth
              />
              <br></br>
            </SoftBox>
          </form>
      </Drawer>
      {/* editar */}
      <Drawer
        title="Editar Potrero"
        anchor="right"
        size={size}
        open={drawerVisibleEdit}
        
        onClose={closeEditArbol}
        footer={
          <Space>
            <SoftButton  onClick={closeEditArbol}>Cancelar</SoftButton>
            <SoftButton type="primary" color="primary" onClick={handleSaveChange}>Guardar</SoftButton>
          </Space>
        }
      >
          <form encType="multipart/form-data">
            <SoftBox p={2}>
              <br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;
              <SoftTypography variant="button" fontWeight="regular" color="text">
                &nbsp;<strong><br></br>
              {"\n"}&nbsp;
              {"\n"}&nbsp;Nombre&nbsp;&nbsp;</strong><br></br>&nbsp;&nbsp;
              </SoftTypography>
              <SoftInput
                // type="text"
                name="nombre"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)} 
                fullWidth
              />
            </SoftBox>
          </form>
      </Drawer>
    </>
  );
}

export default Potrero;
